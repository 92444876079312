/**
    Copyright Highway9 Networks Inc.
 */

type Props = {
    size?: number;
};

const CheckCircleOverflown = ({
    size = 16,
}: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
            <g clipPath="url(#clip0_301_942)">
                <path d="M14.6667 7.38662V7.99995C14.6659 9.43756 14.2003 10.8364 13.3396 11.9878C12.4788 13.1393 11.2689 13.9816 9.89025 14.3892C8.51163 14.7968 7.03818 14.7479 5.68966 14.2497C4.34113 13.7515 3.18978 12.8307 2.40732 11.6247C1.62485 10.4186 1.2532 8.99199 1.34779 7.55749C1.44239 6.12299 1.99815 4.7575 2.9322 3.66467C3.86625 2.57183 5.12853 1.81021 6.5308 1.49338C7.93307 1.17656 9.40019 1.32151 10.7133 1.90662" stroke="#00B95B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.6667 2.66663L8 9.33996L6 7.33996" stroke="#00B95B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_301_942">
                    <rect width={size} height={size} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CheckCircleOverflown;