var r = Object.defineProperty;
var h = (t, e, s) => e in t ? r(t, e, { enumerable: !0, configurable: !0, writable: !0, value: s }) : t[e] = s;
var o = (t, e, s) => (h(t, typeof e != "symbol" ? e + "" : e, s), s);
import { useState as l } from "react";
class p {
  constructor() {
    o(this, "synth", window.speechSynthesis);
    // @ts-expect-error This should be called in the constructor but it's not.
    o(this, "voices", this.synth.getVoices());
    o(this, "lang", "en-US");
  }
  speak(e) {
    if (!this.synth) {
      console.warn("Your browser does not support speech synthesis");
      return;
    }
    this.synth.speaking && (console.error("speechSynthesis.speaking"), this.synth.cancel());
    const s = new SpeechSynthesisUtterance(e), c = this.synth.getVoices().filter((n) => n.lang === this.lang && n.localService);
    s.voice = c.find((n) => n.name.includes("Mark")) ?? c[0], console.log(s.voice), this.synth.speak(s);
  }
  stop() {
    this.synth.cancel();
  }
  setVoice(e) {
    this.voices = this.synth.getVoices().filter((s) => s.lang === e);
  }
  listofLang() {
    const e = this.synth.getVoices().map((s) => s.lang);
    return Array.from(new Set(e));
  }
}
const i = new p(), S = () => {
  const [t, e] = l(!1);
  return { speak: (a) => {
    t && i.speak(a);
  }, setVoice: (a) => {
    i.setVoice(a);
  }, listofLang: () => i.listofLang(), stopSpeaking: () => {
    i.stop();
  }, enabled: t, toggleTranscription: () => {
    e(!t);
  } };
};
export {
  S as useTextToSpeak
};
