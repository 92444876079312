import { jsxs as l, jsx as t } from "react/jsx-runtime";
const o = ({
  color: e = "#545454",
  size: C = 24,
  title: i
}) => /* @__PURE__ */ l("svg", { viewBox: "0 0 24 24", role: "img", style: {
  width: C,
  height: C
}, children: [
  /* @__PURE__ */ t("title", { children: i }),
  /* @__PURE__ */ t(
    "path",
    {
      d: "M18.72 14.7599C19.07 13.9099 19.26 12.9999 19.26 11.9999C19.26 11.2799 19.15 10.5899 18.96 9.94995C18.31 10.0999 17.63 10.1799 16.92 10.1799C15.466 10.1815 14.0329 9.83347 12.7415 9.16517C11.4502 8.49687 10.3384 7.5279 9.5 6.33995C8.60396 8.51069 6.91172 10.2566 4.77 11.2199C4.73 11.4699 4.73 11.7399 4.73 11.9999C4.73 12.9547 4.91804 13.9 5.2834 14.7821C5.64875 15.6641 6.18425 16.4655 6.85933 17.1406C8.22272 18.504 10.0719 19.2699 12 19.2699C13.05 19.2699 14.06 19.0399 14.97 18.6299C15.54 19.7199 15.8 20.2599 15.78 20.2599C14.14 20.8099 12.87 21.0799 12 21.0799C9.58 21.0799 7.27 20.1299 5.57 18.4199C4.53505 17.3899 3.76627 16.1234 3.33 14.7299H2V10.1799H3.09C3.42024 8.57246 4.17949 7.08436 5.28719 5.87354C6.39489 4.66272 7.80971 3.77435 9.38153 3.3027C10.9533 2.83106 12.6235 2.79372 14.2149 3.19465C15.8062 3.59559 17.2593 4.41984 18.42 5.57995C19.6798 6.83553 20.5393 8.43623 20.89 10.1799H22V14.7299H21.94L18.38 17.9999L13.08 17.3999V15.7299H17.91L18.72 14.7599ZM9.27 11.7699C9.57 11.7699 9.86 11.8899 10.07 12.1099C10.281 12.3227 10.3995 12.6103 10.3995 12.9099C10.3995 13.2096 10.281 13.4972 10.07 13.7099C9.86 13.9199 9.57 14.0399 9.27 14.0399C8.64 14.0399 8.13 13.5399 8.13 12.9099C8.13 12.2799 8.64 11.7699 9.27 11.7699ZM14.72 11.7699C15.35 11.7699 15.85 12.2799 15.85 12.9099C15.85 13.5399 15.35 14.0399 14.72 14.0399C14.09 14.0399 13.58 13.5399 13.58 12.9099C13.58 12.6076 13.7001 12.3176 13.9139 12.1038C14.1277 11.8901 14.4177 11.7699 14.72 11.7699Z",
      fill: e
    }
  )
] });
export {
  o as default
};
