/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
import { DataObject, ObjectReference } from "./dataObject";
import Radio from "./radio";
import { MarkerData } from "../components/shared/useMap";
import { PlanCoods } from "~/components/shared/d3/FloorPlan";
import { EDGE_TYPE, FREQUENCY_SELECTION_LOGIC } from "~/constants";

export interface RadioGroup extends DataObject {
  id?: string;
  name: string;
  description?: string;
  /**@enum {string} "INDOOR" | "OUTDOOR";*/
  zoneType: string;
  radioSite: ObjectReference;

  overrideMacroEnbIdList?: boolean;
  macroEnbIdList?: number[];
  edges: EgdeReference[];
  mobilityProfile: ObjectReference | null;
  technology: string;

  map?: Location[];
  // location: Location;
  frequency: Frequency;
  sas: SAS;
  datapath: {
    localExit: LocalExit;
  };

  // merge with radioGroup
  floor?: number;
  area?: number;
  plan?: {
    calibrationPoints: {
      floorPlan?: {
        imageData?: string;
        imageId?: string;
        imageName?: string;
      };
      rfPlan?: {
        imageData?: string;
        imageId?: string;
        imageName?: string;
      };
      map: PlanMapData;
    };
  };

  /**Not Available in the API */
/*Whenever (_)underscore used that means value manually pushed into API from UI  */
  countAPs?: number;
  connectedAPs?: number;
  radios?: Radio[];
  radioCoordinates?: MarkerData[];
  radioFloorPlanCoods?: PlanCoods[];
  // radioGroups?: RadioGroup[];
  overallStatus?: string;
  _plmnList?: string[];
  _vendorList?: string[];
}

export interface EgdeReference {
  edge: {
    id: string;
    name?: string;
    /**@enum {string} "PRIVATE_CELLULAR" | "CARRIER_GATEWAY" */
    type?: string;
  };
  /** @enum {string} 'PSK' | 'CERTIFICATE' | "DISABLED"; */
  ipsecMode: string;
  /** Only available if edge type is CARIER_GATEWAY */
  carriers?: ObjectReference[];
}

interface Location {
  latitude: number;
  longitude: number;
  zoom?: number;
  name?: string;
  // ntpServer1: string;
  // ntpServer2: string;
  // ntpPort1: number;
  // ntpPort2: number;
}

interface Frequency {
  band: string;
  bandwidth: number;
  // bandwidth : 5 | 10 | 15 | 20;
  subframeAssignment: number;
  specialSubframePattern: number;
}

interface SAS {
  sasEnabled: boolean;
  sasUserId: string;
  frequencySelectionLogic: string;
  // frequencySelectionLogic : 'Power' | "Bandwidth" | 'Frequency';
  allowOnlySelectedFrequency: boolean;
}

interface LocalExit {
  enabled: boolean;
  mode: string;
  ipPool: string;
  ipPoolNetmask: string;
  enableStaticIp: boolean;
  staticIpStart: string;
  staticIpEnd: string;
}

interface CalibrationPointsMap {
  id?: string;
  latitude: number;
  longitude: number;
  name?: string;
}

export interface PlanMapData {
  points: CalibrationPointsMap[];
  zoom?: number;
  center?: CalibrationPointsMap;
  heading: number;
  view?: string;
}


export const defaultRadioGroup = {
  name: "",
  description: "",
  zoneType: "INDOOR",
  technology: "4G",
  radioSite: {
    id: "",
    name: "",
  },
  edges: [
    {
      edge: {
        id: "",
        name: "",
        type: EDGE_TYPE.PRIVATE_CELLULAR,
      },
      ipsecMode: "",
    },
  ],
  sas: {
    sasEnabled: false,
    sasUserId: "",
    frequencySelectionLogic: FREQUENCY_SELECTION_LOGIC.PBF,
    allowOnlySelectedFrequency: false,
  },
  datapath: {
    localExit: {
      enabled: false,
      mode: "NAT",
      ipPool: "10.0.0.0",
      enableStaticIp: false,
      staticIpStart: "",
      staticIpEnd: "",
      ipPoolNetmask: "255.255.255.0",
    },
  },

  frequency: {
    band: "B48",
    bandwidth: 20,
    specialSubframePattern: 7,
    subframeAssignment: 1,
  },
  mobilityProfile: null,

  floor: 1,
  area : 2000,
  plan: {
    calibrationPoints: {
      map: {
        heading: 0,
        zoom: 18,
        points: [],
        view: "ROADMAP",
      },
    },
  },
};