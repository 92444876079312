import ye, { jsxs as h, jsx as r, Fragment as Ce } from "react/jsx-runtime";
import { Box as m, Typography as E, Button as te } from "@mui/material";
import { d as Be, D as ae } from "../../DateTimeSelectorMenu-62cb07f8.js";
import { i as Ae } from "../../styled-996d2964.js";
import { r as Me } from "../../createSvgIcon-252c9b61.js";
import { styles as l } from "./DateTimeSelect.style.js";
import { useState as x, useMemo as H, useCallback as se, useLayoutEffect as ke } from "react";
import { AnchorDropdownMenu as j } from "./AnchorDropdownMenu.js";
import { LiveDataPresetsMenu as Se } from "./LiveDataPresetsMenu.js";
import t from "moment-timezone";
import { calculateDateRange as b, generateTimeSlots as Ee, formatPresetLabel as ge } from "../../utilities/datetime.js";
import { Seek as le } from "./Seek.js";
import { useInterval as Le } from "../../utilities/index.js";
var q = {}, Ie = Ae;
Object.defineProperty(q, "__esModule", {
  value: !0
});
var ue = q.default = void 0, Re = Ie(Me()), we = ye, Ye = (0, Re.default)(/* @__PURE__ */ (0, we.jsx)("path", {
  d: "M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
}), "KeyboardArrowDownOutlined");
ue = q.default = Ye;
const _e = [
  {
    label: "Last 15 Mins",
    value: "15minute"
  },
  {
    label: "Last 30 Mins",
    value: "30minute"
  },
  {
    label: "Last 1 Hour",
    value: "1hour"
  },
  {
    label: "Last 3 Hours",
    value: "3hours"
  },
  {
    label: "Last 6 Hours",
    value: "6hours"
  },
  {
    label: "Last 24 Hours",
    value: "24hours"
  },
  {
    label: "Last 7 Days",
    value: "7days"
  },
  {
    label: "Last 30 Days",
    value: "30days"
  },
  {
    label: "Last 90 Days",
    value: "90days"
  },
  {
    label: "Last 1 Year",
    value: "1year"
  },
  {
    label: "Custom",
    value: "custom"
  }
], Qe = ({
  startTime: y,
  endTime: C,
  interval: g,
  timeSlotInterval: F = 15,
  hideTime: A = !1,
  options: M = _e,
  hideSeek: P = !1,
  hideRange: R = !1,
  rangesOnly: k = !1,
  deltaChangePercent: $ = 0.5,
  minTime: S = 0,
  maxTime: B = 1 / 0,
  timezone: s = t.tz.guess(),
  onChange: w,
  isLive: D = !1,
  disablePresetFiltering: N = !1,
  refreshInterval: re = 6e4
}) => {
  const [L, W] = x(null), [z, Y] = x(null), [K, V] = x(null), [G, J] = x(null), [n, p] = x({
    label: "Custom",
    value: "custom"
  }), [c, d] = x(t().subtract(7, "days").unix()), [o, f] = x(t().unix()), [Q, i] = x(!1), [ne, v] = x(!D), oe = H(() => {
    if (N)
      return M;
    {
      const e = B === 1 / 0 ? t().add(100, "years").unix() : B, a = t.unix(S).startOf("minute").unix(), u = t.unix(e).startOf("minute").unix();
      return M.filter((ee) => {
        if (ee.value === "custom")
          return !0;
        const { startDate: xe, endDate: pe } = b(ee.value), Oe = t.unix(xe).startOf("minute").unix(), be = t.unix(pe).startOf("minute").unix();
        return Oe >= a && be <= u;
      });
    }
  }, [N, M, B, S]), U = H(() => Ee(F), [F]), X = H(() => Math.floor((o - c) * $), [o, c, $]), Z = A ? "MMM DD, YYYY" : "MMM DD, YYYY hh:mm A", O = se((e, a, u) => {
    w && w(e, a, u);
  }, [w]), _ = (e, a) => {
    switch (a) {
      case "live-data-preset-menu":
        W(e.currentTarget);
        break;
      case "custom-time-selector-menu-start":
      case "custom-time-selector-menu-end":
        a === "custom-time-selector-menu-start" ? Y(e.currentTarget) : V(e.currentTarget), i(!0), v(!1);
        break;
    }
    J(a);
  }, I = (e) => {
    switch (e) {
      case "live-data-preset-menu":
        W(null);
        break;
      case "custom-time-selector-menu-start":
        Y(null);
        break;
      case "custom-time-selector-menu-end":
        Y(null), V(null);
        break;
    }
    J(null);
  }, ce = (e) => {
    if (e.value === "custom")
      p(e), i(!0), v(!1), O(c, o, e.value);
    else {
      const { startDate: a, endDate: u } = b(e.value);
      f(t(u).tz(s).valueOf()), d(t(a).tz(s).valueOf()), p(e), O(a, u, e.value), i(!1);
    }
    I(
      "live-data-preset-menu"
      /* LIVE_DATA_PRESET_MENU */
    );
  }, de = (e) => {
    p({
      label: "Custom",
      value: "custom"
    }), _(
      e,
      "custom-time-selector-menu-start"
      /* CUSTOM_TIME_SELECTOR_MENU_START */
    );
  }, fe = (e) => {
    p({
      label: "Custom",
      value: "custom"
    }), _(
      e,
      "custom-time-selector-menu-end"
      /* CUSTOM_TIME_SELECTOR_MENU_END */
    );
  }, ie = () => {
    v(!0), O(c, o, n.value);
  }, ve = () => {
    const { startDate: e, endDate: a } = b(n.value);
    d(t(e).tz(s).valueOf()), f(t(a).tz(s).valueOf()), O(e, a, n.value);
  }, T = (e, a) => {
    const u = a * e;
    e === 1 && o + u > t().unix() || e === -1 && c + u < S || e === 1 && o + u > B || (d(t(c + u).tz(s).valueOf()), f(t(o + u).tz(s).valueOf()), O(c + u, o + u, n.value));
  }, me = (e) => {
    if (e > o)
      return;
    d(t(e).tz(s).valueOf());
    const a = t(e).startOf("second").unix(), u = t(c).startOf("second").unix();
    a !== u && (v(!1), i(!0));
  }, De = (e) => {
    if (e < c)
      return;
    f(t(e).tz(s).valueOf());
    const a = t(e).startOf("second").unix(), u = t(o).startOf("second").unix();
    a !== u && (v(!1), i(!0));
  };
  ke(() => {
    if (g)
      if (g === "custom")
        if (p({
          label: "Custom",
          value: "custom"
        }), y && C)
          d(t(y).tz(s).valueOf()), f(t(C).tz(s).valueOf()), v(!0), i(!0);
        else {
          const { startDate: e, endDate: a } = b("7days");
          d(t(e).tz(s).valueOf()), f(t(a).tz(s).valueOf()), v(!0), i(!0);
        }
      else {
        const e = M.find((a) => a.value === g) || {
          label: "Custom",
          value: "custom"
        };
        if (p(e), e.value === "custom")
          if (y && C)
            d(t(y).tz(s).valueOf()), f(t(C).tz(s).valueOf()), v(!0), i(!0);
          else {
            const { startDate: a, endDate: u } = b("7days");
            d(t(a).tz(s).valueOf()), f(t(u).tz(s).valueOf()), v(!0), i(!0);
          }
        else {
          const { startDate: a, endDate: u } = b(e.value);
          d(t(a).tz(s).valueOf()), f(t(u).tz(s).valueOf());
        }
      }
    else if (y && C)
      p({
        label: "Custom",
        value: "custom"
      }), v(!0), i(!0), d(t(y).tz(s).valueOf()), f(t(C).tz(s).valueOf());
    else {
      const { startDate: e, endDate: a } = b("7days");
      d(t(e).tz(s).valueOf()), f(t(a).tz(s).valueOf()), p({
        label: "7 Days",
        value: "7days"
      });
    }
  }, [y, C, g, M, s, O]);
  const he = se(() => {
    if (n.value !== "custom" && D) {
      const { startDate: e, endDate: a } = b(n.value);
      f(t(a).tz(s).valueOf()), d(t(e).tz(s).valueOf()), O(e, a, n.value), v(!0), i(!1);
    }
  }, [n, s, O, D]);
  return Le(he, D ? re : null, !1, [n]), /* @__PURE__ */ h(m, { sx: l.container, children: [
    /* @__PURE__ */ h(m, { sx: l.innerContainer, children: [
      !R && /* @__PURE__ */ h(m, { sx: [l.dateTimeBox, L && l.activeDateTimeBox, k && l.dateTimeBoxRightBorder], onClick: (e) => _(
        e,
        "live-data-preset-menu"
        /* LIVE_DATA_PRESET_MENU */
      ), children: [
        /* @__PURE__ */ r(Be, { sx: l.icon }),
        /* @__PURE__ */ r(E, { sx: l.timeLabel, children: n.label }),
        n.value !== "custom" && D && /* @__PURE__ */ h(m, { sx: [l.liveIndicatorBox, !L && { backgroundColor: "#EFEFEF" }], children: [
          /* @__PURE__ */ r(m, { sx: l.liveDot }),
          /* @__PURE__ */ r(E, { sx: l.liveLabel, children: "LIVE" })
        ] }),
        /* @__PURE__ */ r(ue, { sx: [l.icon, { fontSize: "16px" }] })
      ] }),
      !k && /* @__PURE__ */ h(m, { sx: [l.dateRangeBox, R && l.dateRangeLeftBorder], children: [
        n.value === "custom" && !P && /* @__PURE__ */ r(le, { direction: -1, distance: X, color: l.arrowIcon.color, onSeek: T }),
        /* @__PURE__ */ h(m, { sx: l.dateBox, children: [
          /* @__PURE__ */ r(E, { onClick: de, sx: l.dateText, children: n.value === "custom" || !D ? t.unix(c).tz(s).format(Z) : `~ ${ge(n.value)} ago` }),
          G === "custom-time-selector-menu-start" && /* @__PURE__ */ r(m, { sx: l.activeDateBorder })
        ] }),
        /* @__PURE__ */ r(E, { sx: l.dateToLabel, children: "to" }),
        /* @__PURE__ */ h(m, { sx: l.dateBox, children: [
          /* @__PURE__ */ r(E, { onClick: fe, sx: l.dateText, children: n.value === "custom" || !D ? t.unix(o).tz(s).format(Z) : "Now" }),
          G === "custom-time-selector-menu-end" && /* @__PURE__ */ r(m, { sx: l.activeDateBorder })
        ] }),
        n.value === "custom" && !P && /* @__PURE__ */ r(le, { direction: 1, distance: X, color: l.arrowIcon.color, onSeek: T })
      ] })
    ] }),
    !k && /* @__PURE__ */ h(m, { children: [
      D && !Q && /* @__PURE__ */ r(te, { variant: "outlined", sx: l.refreshButton, onClick: ve, children: "Refresh" }),
      !D || Q ? /* @__PURE__ */ r(te, { variant: "contained", sx: l.applyButton, onClick: ie, disabled: ne, children: "Apply" }) : null
    ] }),
    !R && // Live Data Preset Menu
    /* @__PURE__ */ r(
      j,
      {
        menuId: "live-data-preset-menu",
        anchorEl: L,
        open: !!L,
        onClose: () => I(
          "live-data-preset-menu"
          /* LIVE_DATA_PRESET_MENU */
        ),
        menuWidth: 360,
        children: /* @__PURE__ */ r(
          Se,
          {
            presetOptions: oe,
            activePreset: n,
            onPresetChange: ce,
            isLive: D,
            isRangeOnly: k
          }
        )
      }
    ),
    !k && /* @__PURE__ */ h(Ce, { children: [
      /* @__PURE__ */ r(
        j,
        {
          menuId: "custom-time-selector-menu-start",
          anchorEl: z,
          open: !!z,
          onClose: () => I(
            "custom-time-selector-menu-start"
            /* CUSTOM_TIME_SELECTOR_MENU_START */
          ),
          menuWidth: A ? 280 : 420,
          children: /* @__PURE__ */ r(
            ae,
            {
              date: c,
              setDate: me,
              dateType: "start",
              timeSlots: U,
              isTimeHidden: A,
              minTime: S,
              maxTime: B,
              startDate: c,
              endDate: o
            }
          )
        }
      ),
      /* @__PURE__ */ r(
        j,
        {
          menuId: "custom-time-selector-menu-end",
          anchorEl: K,
          open: !!K,
          onClose: () => I(
            "custom-time-selector-menu-end"
            /* CUSTOM_TIME_SELECTOR_MENU_END */
          ),
          menuWidth: A ? 280 : 420,
          children: /* @__PURE__ */ r(
            ae,
            {
              date: o,
              setDate: De,
              dateType: "end",
              timeSlots: U,
              isTimeHidden: A,
              minTime: S,
              maxTime: B,
              startDate: c,
              endDate: o
            }
          )
        }
      )
    ] })
  ] });
};
export {
  Qe as DateTimeSelector
};
