import { ApiToken } from "~/types/ApiToken";
import APIService from "./APIServices";

const TokenAPI = new APIService<ApiToken>("apiToken");

export const ApiTokenService = {
  getApiTokensData : TokenAPI.getAll,
  createApiToken : TokenAPI.create,
  deleteApiToken : TokenAPI.delete,
  updateApiToken : TokenAPI.update
};
