/**
 * Copyright Highway9 Networks Inc.
 */
import moment from 'moment'
import { useLocation } from "react-router-dom";
import { Images } from "./GraphicalResources";
import { LOCATION_TYPE } from "~/constants";
import React from "react";

// const format = "D-MMM-YYYY h:mm:ss a ";
// const formatmillisec = "D-MMM-YYYY h:mm:ss.SSS a ";
// const format2 = "h:mm a ";
// const formatShort = "M/D h:mm";
export const trimSpaces = (str, strCase = null) => {
  let trimedString = str.replace(/\s/g, "");
  if (strCase === "lower") {
    trimedString = trimedString.toLowerCase();
  } else if (strCase === "upper") {
    trimedString = trimedString.toUpperCase();
  }
  return trimedString;
};

export const generateLocationTypeCell = (row) => {
  const locationType = row.location.locationType;
  if (locationType) {
    return (
      <>
        {locationType === LOCATION_TYPE.GCP ? (
          (<><img src={Images.GCP} alt="GCP" height="16px" />&nbsp;</>)
        ) : locationType === LOCATION_TYPE.AWS ? (
          (<><img src={Images.AWS} alt="AWS" height="16px" />&nbsp;</>)
        ) : (
          (<><img src={Images.onPrem} alt="onPrem" height="16px" />&nbsp;</>)
        )}
        &nbsp;
        {locationType === LOCATION_TYPE.AWS
          ? LOCATION_TYPE.AWS
          : locationType === LOCATION_TYPE.GCP
            ? LOCATION_TYPE.GCP
            : LOCATION_TYPE.PRIVATE_CLOUD}
      </>
    );
  } else {
    return null;
  }
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const updateExistingArray = (e, source, existingData = []) => {
  const files = e.currentTarget.files;
  Object.keys(files).forEach((i) => {
    const file = files[i];
    const filename = file.name;
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = reader.result;
      var file = {
        name: filename,
        content: text,
      };
      console.log("file....", file);
      existingData.push(file);

      //server call for uploading or reading the files one-by-one
      //by using 'reader.result' or 'file'
    };
    reader.readAsBinaryString(file);
  });
  return existingData;
};

export function getDuration(endUnixTime, startUnixTime) {
  const startTime = moment(startUnixTime);
  const endTime = moment(endUnixTime);
  const duration = moment.duration(endTime.diff(startTime));
  const seconds = duration.asSeconds() * 1000;
  const minutes = (duration.asMinutes() * 1000).toFixed(0);
  const hours = (duration.asHours() * 1000).toFixed(0);
  const days = (duration.asDays() * 1000).toFixed(0);

  if (seconds < 60) {
    return `${seconds} seconds`;
  }
  if (minutes < 60) {
    return `${minutes} minute ${seconds % 60} seconds`;
  }
  if (hours < 24) {
    return `${hours} hours ${minutes % 60} minutes`;
  }
  if (days < 7) {
    return `${days} days ${hours % 24} hours`;
  }
  return `${days} days ${hours % 24} hours`;
}

export const truncateString = (str, length) => {
  if (str.length > length) {
    // Return the first {18} characters of the string followed by an ellipsis
    return str.slice(0, length) + " ...";
  }
  // Return the original string if it's shorter than 18 characters
  return str;
};