import * as u from "react";
function l(t) {
  return t && t.ownerDocument || document;
}
function s(t, e) {
  typeof t == "function" ? t(e) : t && (t.current = e);
}
const o = typeof window < "u" ? u.useLayoutEffect : u.useEffect, d = o;
function h(...t) {
  return u.useMemo(() => t.every((e) => e == null) ? null : (e) => {
    t.forEach((n) => {
      s(n, e);
    });
  }, t);
}
const c = {};
function i(t, e) {
  const n = u.useRef(c);
  return n.current === c && (n.current = t(e)), n;
}
const f = [];
function a(t) {
  u.useEffect(t, f);
}
class r {
  constructor() {
    this.currentId = null, this.clear = () => {
      this.currentId !== null && (clearTimeout(this.currentId), this.currentId = null);
    }, this.disposeEffect = () => this.clear;
  }
  static create() {
    return new r();
  }
  /**
   * Executes `fn` after `delay`, clearing any previously scheduled call.
   */
  start(e, n) {
    this.clear(), this.currentId = setTimeout(() => {
      this.currentId = null, n();
    }, e);
  }
}
function E() {
  const t = i(r.create).current;
  return a(t.disposeEffect), t;
}
export {
  r as T,
  h as a,
  E as b,
  l as o,
  s,
  d as u
};
