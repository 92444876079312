import { EdgePool } from "~/types/EdgePool";
import APIService from "./APIServices";
import { Edge } from "~/types/edge";
import { Device } from "~/types/device";

export const edgeAPI = new APIService<Edge>("edges");
const edgePoolAPI = new APIService<EdgePool>("edgePools");
const deviceAPI = new APIService<Device>("devices");

export const edgeService = {
  getEdges: edgeAPI.getAll,
  // createEdges: edgeAPI.create,
  deleteEdges: edgeAPI.delete,
  updateEdge: edgeAPI.update,
  getMetrics: edgeAPI.getMetrics,
  getConnectedDevicesCount: deviceAPI.getMetrics,
  getAggregateMetrics: edgeAPI.getAggregateMetrics,
  getEdgePool: edgePoolAPI.getAll,
  addEdgePool: edgePoolAPI.create,
  updateEdgePool: edgePoolAPI.update,
  deleteEdgePool: edgePoolAPI.delete
};
