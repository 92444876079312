import { o as i, u as a } from "./useTimeout-890b47bd.js";
import * as r from "react";
function d(...e) {
  return e.reduce((t, n) => n == null ? t : function(...u) {
    t.apply(this, u), n.apply(this, u);
  }, () => {
  });
}
function f(e, t = 166) {
  let n;
  function o(...u) {
    const c = () => {
      e.apply(this, u);
    };
    clearTimeout(n), n = setTimeout(c, t);
  }
  return o.clear = () => {
    clearTimeout(n);
  }, o;
}
function l(e) {
  return i(e).defaultView || window;
}
function m(e) {
  const t = r.useRef(e);
  return a(() => {
    t.current = e;
  }), r.useRef((...n) => (
    // @ts-expect-error hide `this`
    (0, t.current)(...n)
  )).current;
}
export {
  d as c,
  f as d,
  l as o,
  m as u
};
