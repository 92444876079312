/**
 * Copyright Highway9 Networks Inc.
 */

import { DataObject } from "./dataObject";
import { BitRate } from "./deviceGroup";

export interface IDNN extends DataObject {
  ambr: BitRate;
  defaultQci: number;
  name: string;
  runtimeInfo?: string;
}

export const defaultDNN: IDNN = {
  ambr: {
    uplink: 4000000000,
    downlink: 4000000000
  },
  defaultQci: 9,
  name: ""
}