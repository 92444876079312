/**
    Copyright Highway9 Networks Inc.
 */

type Props = {
    size?: number;
    color?: string;
};

const EmergencySvg = ({
    size = 24,
    color = '#226A58'
}: Props) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 23"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
        >
            <path
                d="M12 0.125C9.75024 0.125 7.551 0.792132 5.68039 2.04203C3.80978 3.29193 2.35182 5.06847 1.49088 7.14698C0.629929 9.22548 0.404666 11.5126 0.843573 13.7192C1.28248 15.9257 2.36584 17.9525 3.95667 19.5433C5.54749 21.1342 7.57432 22.2175 9.78085 22.6564C11.9874 23.0953 14.2745 22.8701 16.353 22.0091C18.4315 21.1482 20.2081 19.6902 21.458 17.8196C22.7079 15.949 23.375 13.7498 23.375 11.5C23.3718 8.48414 22.1724 5.59271 20.0398 3.46018C17.9073 1.32764 15.0159 0.128185 12 0.125ZM18.5002 14.242C18.6671 14.3383 18.7975 14.487 18.8713 14.665C18.9451 14.843 18.9581 15.0403 18.9082 15.2264C18.8583 15.4126 18.7484 15.577 18.5955 15.6943C18.4427 15.8115 18.2553 15.8751 18.0627 15.875C17.909 15.8755 17.758 15.8351 17.6252 15.758L12.875 13.0159V18.5C12.875 18.7321 12.7828 18.9546 12.6187 19.1187C12.4546 19.2828 12.2321 19.375 12 19.375C11.7679 19.375 11.5454 19.2828 11.3813 19.1187C11.2172 18.9546 11.125 18.7321 11.125 18.5V13.0159L6.37485 15.758C6.17393 15.8737 5.9353 15.905 5.71135 15.8449C5.48741 15.7848 5.29647 15.6383 5.18047 15.4375C5.0643 15.2366 5.03269 14.9978 5.09258 14.7736C5.15248 14.5494 5.29897 14.3582 5.49985 14.242L10.25 11.5L5.49985 8.75797C5.29882 8.64194 5.15212 8.4508 5.09202 8.22661C5.06227 8.1156 5.05466 7.99981 5.06965 7.88586C5.08464 7.77192 5.12193 7.66204 5.17938 7.5625C5.23683 7.46296 5.31333 7.37572 5.4045 7.30574C5.49567 7.23577 5.59973 7.18443 5.71074 7.15468C5.93494 7.09458 6.17382 7.126 6.37485 7.24203L11.125 9.98406V4.5C11.125 4.26794 11.2172 4.04538 11.3813 3.88128C11.5454 3.71719 11.7679 3.625 12 3.625C12.2321 3.625 12.4546 3.71719 12.6187 3.88128C12.7828 4.04538 12.875 4.26794 12.875 4.5V9.98406L17.6252 7.24203C17.8262 7.126 18.0651 7.09458 18.2893 7.15468C18.5135 7.21478 18.7046 7.36147 18.8206 7.5625C18.9367 7.76353 18.9681 8.00241 18.908 8.22661C18.8479 8.4508 18.7012 8.64194 18.5002 8.75797L13.75 11.5L18.5002 14.242Z"
                fill={color}
            />
        </svg>
    );
};

export default EmergencySvg;
