import { jsx as a } from "react/jsx-runtime";
import { Menu as c } from "@mui/material";
import { styles as l } from "./DateTimeSelect.style.js";
const f = ({
  anchorEl: o,
  open: t,
  onClose: e,
  children: i,
  menuId: n,
  menuWidth: r,
  ...p
}) => /* @__PURE__ */ a(
  c,
  {
    id: n,
    anchorEl: o,
    open: t,
    onClose: e,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    slotProps: {
      paper: { sx: {
        ...l.dateTimeSelectorMenuStyle["& .MuiPaper-root"],
        width: r ? `${r}px` : "auto"
      } }
    },
    ...p,
    children: i
  }
);
export {
  f as AnchorDropdownMenu
};
