import { useRef as s, useEffect as a } from "react";
function g(t, n = !1) {
  const r = Intl.NumberFormat("en", { notation: "compact" }), e = n ? Math.abs(t) : t;
  return r.format(e);
}
const m = "MMM DD, YYYY | hh:mmA";
async function h(t) {
  if (navigator != null && navigator.clipboard)
    return navigator.clipboard.writeText(t);
}
function v(t, n) {
  const r = t.length;
  if (r === 0)
    return -1;
  let e = 0, c = r - 1;
  const o = r > 1 ? t[1] - t[0] : 0;
  if (n < t[0] - o || n > t[r - 1] + o)
    return -1;
  for (; e <= c; ) {
    const u = Math.floor((e + c) / 2), i = t[u];
    if (i < n)
      e = u + 1;
    else if (i > n)
      c = u - 1;
    else
      return u;
  }
  return Math.abs(t[e] - n) < Math.abs(t[c] - n) ? e : c;
}
const M = (t, n = 18) => t.length > n ? t.slice(0, n) + " ..." : t;
function B(t) {
  if (t < 1)
    return { value: t, unit: "Bytes" };
  if (t === 1)
    return { value: 1, unit: "Byte" };
  const n = 1024, r = ["Bytes", "KB", "MB", "GB", "TB"], e = Math.floor(Math.log(t) / Math.log(n));
  return { value: parseFloat((t / Math.pow(n, e)).toFixed(2)), unit: r[e] };
}
function b(t) {
  return Number.isInteger(t) ? String(t) : t.toFixed(2);
}
function d(t, n, r = !1, e = []) {
  const c = s(), o = s(null);
  function u() {
    o.current !== null && (clearInterval(o.current), o.current = null);
  }
  return a(() => {
    c.current = t;
  }, [t]), a(() => {
    if (n === null) {
      u();
      return;
    }
    const i = async () => {
      c.current && await c.current();
    };
    return r && i(), o.current = window.setInterval(i, n), () => u();
  }, [n, r, ...e]), { ClearInterval: u };
}
const p = (t) => {
  let n, r, e;
  if (t != null && t.startsWith("#")) {
    const o = parseInt(t.slice(1), 16);
    n = o >> 16 & 255, r = o >> 8 & 255, e = o & 255;
  } else {
    const u = document.createElement("canvas").getContext("2d");
    if (u) {
      u.fillStyle = t;
      const l = u.fillStyle.match(/\d+/g);
      if (l)
        [n, r, e] = l.map(Number);
      else
        return 1;
    } else
      return 1;
  }
  return (0.2126 * n + 0.7152 * r + 0.0722 * e) / 255;
};
export {
  m as DateTimeFormat,
  h as copyToClipBoard,
  g as formatCompactNumber,
  b as formatNumber,
  B as getFormattedBytes,
  p as getTextColorFromBackground,
  v as getTimelineIndex,
  M as truncateString,
  d as useInterval
};
