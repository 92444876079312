import React, { useEffect, useMemo } from 'react'
import "@h9/ui-lib/dist/assets/Chatbot.css";
import "./Assistant.style.css";

import { sendAssistantMessage } from './utils';
import { useAppDispatch, useAppSelector } from '~/store/hooks';

import { Chatbot, useStreamAdapter } from '@h9/ui-lib';
import { utilityActions } from '~/store/context/utilitySlice';
import { LinkOff, Link } from '@mui/icons-material';

export const CHATBOT_WIDTH = '25vw';
// const BOT_NAME = 'Virtual Engineer';

const styles: Record<string, React.CSSProperties> = {
    button: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: '1rem',
        zIndex: 1000,
    },
    chat: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: CHATBOT_WIDTH,
        transition: 'height 0.5s',
        boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
        borderRadius: '10px 10px 0 0',
        background: 'white',
        zIndex: 1000,
    },

} as const

type Props = {}

const Assistant = (props: Props) => {
    const dispatch = useAppDispatch();
    const setOpen = (open: boolean) => { dispatch(utilityActions.setChatBotOpen(open)) }


    const open = useAppSelector((state) => state.utility.chatBot.open);
    const height = useAppSelector((state) => state.utility.chatBot.height);
    const maximized = useAppSelector((state) => state.utility.chatBot.maximize);
    const linkSync = useAppSelector((state) => state.utility.chatBot.syncLinks);

    const username = useAppSelector((state) => state.auth.user?.name);


    const adapter = useStreamAdapter(sendAssistantMessage)

    // Get the conversation from the session storage if it exists IF the chatbot is open
    const initConversation = useMemo(() => {
        if (!open) return undefined;
        return sessionStorage.getItem('chatHistory') ? JSON.parse(sessionStorage.getItem('chatHistory')!) : undefined;
    }, [open]);

    // Press Alt + K to toggle the chatbot
    useEffect(() => {

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.altKey && e.key === 'k') {
                setOpen(!open);
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    if (!open) {
        return null;
        // return (
        //     <Button
        //         aria-label="open"
        //         content={BOT_NAME}
        //         onClick={() => { setOpen(true) }}
        //         color='inherit'
        //         className='chatbot-fab' >
        //         <AgentIcon color='#226a58' size={28} />
        //     </Button>
        // )
    }

    return (
        <div style={{
            ...styles.chat,
            height: height,
            borderRadius: maximized ? '0' : '10px 10px 0 0',
        }}>
            <Chatbot
                header={{
                    title: 'Virtual Engineer',
                    actionMenu: [
                        { title: 'Sync Link', icon: <Link />, onClick: () => { dispatch(utilityActions.toogleSyncLinks()) } , hide : linkSync },
                        { title: 'Unsync Link', icon: <LinkOff />, onClick: () => { dispatch(utilityActions.toogleSyncLinks()) } , hide : !linkSync },
                    ]
                }}
                inputOptions={{ prompt: 'Ask me anything...', sendOnSpeech: false }}
                initialMessages={initConversation}
                personaOptions={[
                    { name: 'Virtual Engineer', role: 'assistant', tagline: "Highway 9 Virtual Engineer" },
                    { name: username, role: 'user' },
                    { name: "Randle", role: 'assistant', color: "#ff3200" },
                    { name: "Caroline", role: 'assistant', color: "#5D0EAC" }
                ]}
                adapter={adapter}
                state={{
                    maximize: maximized,
                    onClose: () => { setOpen(false) },
                    onSizeChange: () => { dispatch(utilityActions.maximizeChatBot()) },
                }}
            />
        </div>
    )
}

export default Assistant