const o = {
  common: {
    borderRadius: "8px",
    color: "#5B5B5B !important",
    border: "1px solid #EFEFEF",
    backgroundColor: "#F7F7F7",
    gap: "8px",
    height: "32px",
    ".MuiFilledInput-input": {
      padding: "6px 32px 6px 16px",
      borderRadius: "8px !important",
      fontSize: "12px",
      gap: "8px",
      lineHeight: "24px",
      "&:focus": {
        background: "none"
      }
    },
    "&:before": {
      borderBottom: "none"
    },
    "&.Mui-disabled:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderBottom: "none"
    },
    "&:hover:not(.Mui-disabled, .Mui-error):before": {
      borderBottom: "none",
      borderRadius: "8px"
    },
    ".MuiSelect-icon": {
      color: "#5B5B5B"
    }
  },
  selectedContainer: {
    gap: "8px",
    flexWrap: "nowrap",
    alignItems: "center",
    overflow: "auto"
  }
};
export {
  o as styles
};
