import "../../assets/DatePickerComponent.css";
import { jsx as xe } from "react/jsx-runtime";
import { Box as Ft } from "@mui/material";
import h, { useState as ke, useEffect as Ce, createContext as Bt, useMemo as we, useCallback as G } from "react";
import { styles as qt } from "./DateTimeSelect.style.js";
import J from "moment-timezone";
var y;
(function(e) {
  e.ButtonPrevious = "button_previous", e.ButtonNext = "button_next", e.Root = "root", e.Chevron = "chevron", e.Day = "day", e.DayButton = "day_button", e.CaptionLabel = "caption_label", e.Dropdowns = "dropdowns", e.Dropdown = "dropdown", e.DropdownRoot = "dropdown_root", e.Footer = "footer", e.MonthGrid = "month_grid", e.MonthCaption = "month_caption", e.MonthsDropdown = "months_dropdown", e.Month = "month", e.Months = "months", e.Nav = "nav", e.Week = "week", e.Weeks = "weeks", e.Weekday = "weekday", e.Weekdays = "weekdays", e.WeekNumber = "week_number", e.WeekNumberHeader = "week_number_header", e.YearsDropdown = "years_dropdown";
})(y || (y = {}));
var S;
(function(e) {
  e.disabled = "disabled", e.hidden = "hidden", e.outside = "outside", e.focused = "focused", e.today = "today";
})(S || (S = {}));
var _;
(function(e) {
  e.range_end = "range_end", e.range_middle = "range_middle", e.range_start = "range_start", e.selected = "selected";
})(_ || (_ = {}));
function Ht(e, t, n = {}) {
  return Object.entries(e).filter(([, a]) => a === !0).reduce((a, [o]) => (n[o] ? a.push(n[o]) : t[S[o]] ? a.push(t[S[o]]) : t[_[o]] && a.push(t[_[o]]), a), [t[y.Day]]);
}
function pt(e) {
  return h.createElement("button", { ...e });
}
function It(e) {
  const { size: t = 24, orientation: n = "left", className: r } = e;
  return h.createElement(
    "svg",
    { className: r, width: t, height: t, viewBox: "0 0 24 24" },
    n === "up" && h.createElement("polygon", { points: "6.77 17 12.5 11.43 18.24 17 20 15.28 12.5 8 5 15.28" }),
    n === "down" && h.createElement("polygon", { points: "6.77 8 12.5 13.57 18.24 8 20 9.72 12.5 17 5 9.72" }),
    n === "left" && h.createElement("polygon", { points: "16 18.112 9.81111111 12 16 5.87733333 14.0888889 4 6 12 14.0888889 20" }),
    n === "right" && h.createElement("polygon", { points: "8 18.612 14.1888889 12.5 8 6.37733333 9.91111111 4.5 18 12.5 9.91111111 20.5" })
  );
}
function jt(e) {
  return h.createElement("span", { ...e });
}
function At(e) {
  const { day: t, modifiers: n, ...r } = e;
  return h.createElement("td", { ...r });
}
function Rt(e) {
  const { day: t, modifiers: n, ...r } = e, a = h.useRef(null);
  return h.useEffect(() => {
    var o;
    n.focused && ((o = a.current) == null || o.focus());
  }, [n.focused]), h.createElement("button", { ref: a, ...r });
}
function Gt(e) {
  const { options: t, className: n, components: r, classNames: a, ...o } = e, s = [a[y.Dropdown], n].join(" "), i = t == null ? void 0 : t.find(({ value: c }) => c === o.value);
  return h.createElement(
    "span",
    { "data-disabled": o.disabled, className: a[y.DropdownRoot] },
    h.createElement(r.Select, { className: s, ...o }, t == null ? void 0 : t.map(({ value: c, label: l, disabled: f }) => h.createElement(r.Option, { key: c, value: c, disabled: f }, l))),
    h.createElement(
      "span",
      { className: a[y.CaptionLabel], "aria-hidden": !0 },
      i == null ? void 0 : i.label,
      h.createElement(r.Chevron, { orientation: "down", size: 18, className: a[y.Chevron] })
    )
  );
}
function Lt(e) {
  return h.createElement("div", { ...e });
}
function Qt(e) {
  return h.createElement("div", { ...e });
}
function Xt(e) {
  const { calendarMonth: t, displayIndex: n, ...r } = e;
  return h.createElement("div", { ...r }, e.children);
}
function Vt(e) {
  const { calendarMonth: t, displayIndex: n, ...r } = e;
  return h.createElement("div", { ...r });
}
function $t(e) {
  return h.createElement("table", { ...e });
}
function Jt(e) {
  return h.createElement("div", { ...e });
}
function zt(e) {
  return h.createElement("nav", { ...e });
}
function Kt(e) {
  return h.createElement("option", { ...e });
}
function Zt(e) {
  return h.createElement("div", { ...e });
}
function Ut(e) {
  return h.createElement("select", { ...e });
}
function en(e) {
  return h.createElement("tbody", { ...e });
}
function tn(e) {
  const { week: t, ...n } = e;
  return h.createElement("tr", { ...n });
}
function nn(e) {
  return h.createElement("th", { ...e });
}
function rn(e) {
  return h.createElement(
    "thead",
    null,
    h.createElement("tr", { ...e })
  );
}
function an(e) {
  const { week: t, ...n } = e;
  return h.createElement("td", { ...n });
}
function on(e) {
  return h.createElement("th", { ...e });
}
const sn = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  Button: pt,
  CaptionLabel: jt,
  Chevron: It,
  Day: At,
  DayButton: Rt,
  Dropdown: Gt,
  DropdownNav: Lt,
  Footer: Qt,
  Month: Xt,
  MonthCaption: Vt,
  MonthGrid: $t,
  Months: Jt,
  Nav: zt,
  Option: Kt,
  Root: Zt,
  Select: Ut,
  Week: tn,
  WeekNumber: an,
  WeekNumberHeader: on,
  Weekday: nn,
  Weekdays: rn,
  Weeks: en
}, Symbol.toStringTag, { value: "Module" }));
function cn(e) {
  return {
    ...sn,
    ...e
  };
}
function un(e) {
  const t = {
    "data-mode": e.mode ?? void 0,
    "data-required": "required" in e ? e.required : void 0,
    "data-multiple-months": e.numberOfMonths && e.numberOfMonths > 1 || void 0,
    "data-week-numbers": e.showWeekNumber || void 0
  };
  return Object.entries(e).forEach(([n, r]) => {
    n.startsWith("data-") && (t[n] = r);
  }), t;
}
function O(e) {
  const t = Object.prototype.toString.call(e);
  return e instanceof Date || typeof e == "object" && t === "[object Date]" ? new e.constructor(+e) : typeof e == "number" || t === "[object Number]" || typeof e == "string" || t === "[object String]" ? new Date(e) : /* @__PURE__ */ new Date(NaN);
}
function H(e, t) {
  return e instanceof Date ? new e.constructor(t) : new Date(t);
}
function qe(e, t) {
  const n = O(e);
  return isNaN(t) ? H(e, NaN) : (t && n.setDate(n.getDate() + t), n);
}
function He(e, t) {
  const n = O(e);
  if (isNaN(t))
    return H(e, NaN);
  if (!t)
    return n;
  const r = n.getDate(), a = H(e, n.getTime());
  a.setMonth(n.getMonth() + t + 1, 0);
  const o = a.getDate();
  return r >= o ? a : (n.setFullYear(
    a.getFullYear(),
    a.getMonth(),
    r
  ), n);
}
function dn(e, t) {
  const n = t * 7;
  return qe(e, n);
}
function fn(e, t) {
  return He(e, t * 12);
}
const pe = 6048e5, ln = 864e5;
function ae(e) {
  const t = O(e);
  return t.setHours(0, 0, 0, 0), t;
}
function Ee(e) {
  const t = O(e), n = new Date(
    Date.UTC(
      t.getFullYear(),
      t.getMonth(),
      t.getDate(),
      t.getHours(),
      t.getMinutes(),
      t.getSeconds(),
      t.getMilliseconds()
    )
  );
  return n.setUTCFullYear(t.getFullYear()), +e - +n;
}
function Ie(e, t) {
  const n = ae(e), r = ae(t), a = +n - Ee(n), o = +r - Ee(r);
  return Math.round((a - o) / ln);
}
function hn(e, t) {
  const n = O(e), r = O(t), a = n.getFullYear() - r.getFullYear(), o = n.getMonth() - r.getMonth();
  return a * 12 + o;
}
let mn = {};
function se() {
  return mn;
}
function je(e, t) {
  var i, c, l, f;
  const n = se(), r = (t == null ? void 0 : t.weekStartsOn) ?? ((c = (i = t == null ? void 0 : t.locale) == null ? void 0 : i.options) == null ? void 0 : c.weekStartsOn) ?? n.weekStartsOn ?? ((f = (l = n.locale) == null ? void 0 : l.options) == null ? void 0 : f.weekStartsOn) ?? 0, a = O(e), o = a.getDay(), s = (o < r ? -7 : 0) + 6 - (o - r);
  return a.setDate(a.getDate() + s), a.setHours(23, 59, 59, 999), a;
}
function gn(e) {
  return je(e, { weekStartsOn: 1 });
}
function yn(e) {
  const t = O(e), n = t.getMonth();
  return t.setFullYear(t.getFullYear(), n + 1, 0), t.setHours(23, 59, 59, 999), t;
}
function wn(e) {
  const t = O(e), n = t.getFullYear();
  return t.setFullYear(n + 1, 0, 0), t.setHours(23, 59, 59, 999), t;
}
const bn = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
}, Mn = (e, t, n) => {
  let r;
  const a = bn[e];
  return typeof a == "string" ? r = a : t === 1 ? r = a.one : r = a.other.replace("{{count}}", t.toString()), n != null && n.addSuffix ? n.comparison && n.comparison > 0 ? "in " + r : r + " ago" : r;
};
function be(e) {
  return (t = {}) => {
    const n = t.width ? String(t.width) : e.defaultWidth;
    return e.formats[n] || e.formats[e.defaultWidth];
  };
}
const kn = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
}, Dn = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
}, On = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, Wn = {
  date: be({
    formats: kn,
    defaultWidth: "full"
  }),
  time: be({
    formats: Dn,
    defaultWidth: "full"
  }),
  dateTime: be({
    formats: On,
    defaultWidth: "full"
  })
}, Nn = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
}, vn = (e, t, n, r) => Nn[e];
function ne(e) {
  return (t, n) => {
    const r = n != null && n.context ? String(n.context) : "standalone";
    let a;
    if (r === "formatting" && e.formattingValues) {
      const s = e.defaultFormattingWidth || e.defaultWidth, i = n != null && n.width ? String(n.width) : s;
      a = e.formattingValues[i] || e.formattingValues[s];
    } else {
      const s = e.defaultWidth, i = n != null && n.width ? String(n.width) : e.defaultWidth;
      a = e.values[i] || e.values[s];
    }
    const o = e.argumentCallback ? e.argumentCallback(t) : t;
    return a[o];
  };
}
const Pn = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
}, Sn = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
}, Yn = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  wide: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
}, xn = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
}, Cn = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
}, En = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
}, Tn = (e, t) => {
  const n = Number(e), r = n % 100;
  if (r > 20 || r < 10)
    switch (r % 10) {
      case 1:
        return n + "st";
      case 2:
        return n + "nd";
      case 3:
        return n + "rd";
    }
  return n + "th";
}, _n = {
  ordinalNumber: Tn,
  era: ne({
    values: Pn,
    defaultWidth: "wide"
  }),
  quarter: ne({
    values: Sn,
    defaultWidth: "wide",
    argumentCallback: (e) => e - 1
  }),
  month: ne({
    values: Yn,
    defaultWidth: "wide"
  }),
  day: ne({
    values: xn,
    defaultWidth: "wide"
  }),
  dayPeriod: ne({
    values: Cn,
    defaultWidth: "wide",
    formattingValues: En,
    defaultFormattingWidth: "wide"
  })
};
function re(e) {
  return (t, n = {}) => {
    const r = n.width, a = r && e.matchPatterns[r] || e.matchPatterns[e.defaultMatchWidth], o = t.match(a);
    if (!o)
      return null;
    const s = o[0], i = r && e.parsePatterns[r] || e.parsePatterns[e.defaultParseWidth], c = Array.isArray(i) ? Bn(i, (u) => u.test(s)) : (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I challange you to fix the type
      Fn(i, (u) => u.test(s))
    );
    let l;
    l = e.valueCallback ? e.valueCallback(c) : c, l = n.valueCallback ? (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I challange you to fix the type
      n.valueCallback(l)
    ) : l;
    const f = t.slice(s.length);
    return { value: l, rest: f };
  };
}
function Fn(e, t) {
  for (const n in e)
    if (Object.prototype.hasOwnProperty.call(e, n) && t(e[n]))
      return n;
}
function Bn(e, t) {
  for (let n = 0; n < e.length; n++)
    if (t(e[n]))
      return n;
}
function qn(e) {
  return (t, n = {}) => {
    const r = t.match(e.matchPattern);
    if (!r)
      return null;
    const a = r[0], o = t.match(e.parsePattern);
    if (!o)
      return null;
    let s = e.valueCallback ? e.valueCallback(o[0]) : o[0];
    s = n.valueCallback ? n.valueCallback(s) : s;
    const i = t.slice(a.length);
    return { value: s, rest: i };
  };
}
const Hn = /^(\d+)(th|st|nd|rd)?/i, pn = /\d+/i, In = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
}, jn = {
  any: [/^b/i, /^(a|c)/i]
}, An = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
}, Rn = {
  any: [/1/i, /2/i, /3/i, /4/i]
}, Gn = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
}, Ln = {
  narrow: [
    /^j/i,
    /^f/i,
    /^m/i,
    /^a/i,
    /^m/i,
    /^j/i,
    /^j/i,
    /^a/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ],
  any: [
    /^ja/i,
    /^f/i,
    /^mar/i,
    /^ap/i,
    /^may/i,
    /^jun/i,
    /^jul/i,
    /^au/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ]
}, Qn = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
}, Xn = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
}, Vn = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
}, $n = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
}, Jn = {
  ordinalNumber: qn({
    matchPattern: Hn,
    parsePattern: pn,
    valueCallback: (e) => parseInt(e, 10)
  }),
  era: re({
    matchPatterns: In,
    defaultMatchWidth: "wide",
    parsePatterns: jn,
    defaultParseWidth: "any"
  }),
  quarter: re({
    matchPatterns: An,
    defaultMatchWidth: "wide",
    parsePatterns: Rn,
    defaultParseWidth: "any",
    valueCallback: (e) => e + 1
  }),
  month: re({
    matchPatterns: Gn,
    defaultMatchWidth: "wide",
    parsePatterns: Ln,
    defaultParseWidth: "any"
  }),
  day: re({
    matchPatterns: Qn,
    defaultMatchWidth: "wide",
    parsePatterns: Xn,
    defaultParseWidth: "any"
  }),
  dayPeriod: re({
    matchPatterns: Vn,
    defaultMatchWidth: "any",
    parsePatterns: $n,
    defaultParseWidth: "any"
  })
}, De = {
  code: "en-US",
  formatDistance: Mn,
  formatLong: Wn,
  formatRelative: vn,
  localize: _n,
  match: Jn,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
function Ae(e) {
  const t = O(e), n = H(e, 0);
  return n.setFullYear(t.getFullYear(), 0, 1), n.setHours(0, 0, 0, 0), n;
}
function zn(e) {
  const t = O(e);
  return Ie(t, Ae(t)) + 1;
}
function Z(e, t) {
  var i, c, l, f;
  const n = se(), r = (t == null ? void 0 : t.weekStartsOn) ?? ((c = (i = t == null ? void 0 : t.locale) == null ? void 0 : i.options) == null ? void 0 : c.weekStartsOn) ?? n.weekStartsOn ?? ((f = (l = n.locale) == null ? void 0 : l.options) == null ? void 0 : f.weekStartsOn) ?? 0, a = O(e), o = a.getDay(), s = (o < r ? 7 : 0) + o - r;
  return a.setDate(a.getDate() - s), a.setHours(0, 0, 0, 0), a;
}
function oe(e) {
  return Z(e, { weekStartsOn: 1 });
}
function Re(e) {
  const t = O(e), n = t.getFullYear(), r = H(e, 0);
  r.setFullYear(n + 1, 0, 4), r.setHours(0, 0, 0, 0);
  const a = oe(r), o = H(e, 0);
  o.setFullYear(n, 0, 4), o.setHours(0, 0, 0, 0);
  const s = oe(o);
  return t.getTime() >= a.getTime() ? n + 1 : t.getTime() >= s.getTime() ? n : n - 1;
}
function Kn(e) {
  const t = Re(e), n = H(e, 0);
  return n.setFullYear(t, 0, 4), n.setHours(0, 0, 0, 0), oe(n);
}
function Ge(e) {
  const t = O(e), n = +oe(t) - +Kn(t);
  return Math.round(n / pe) + 1;
}
function Le(e, t) {
  var f, u, g, b;
  const n = O(e), r = n.getFullYear(), a = se(), o = (t == null ? void 0 : t.firstWeekContainsDate) ?? ((u = (f = t == null ? void 0 : t.locale) == null ? void 0 : f.options) == null ? void 0 : u.firstWeekContainsDate) ?? a.firstWeekContainsDate ?? ((b = (g = a.locale) == null ? void 0 : g.options) == null ? void 0 : b.firstWeekContainsDate) ?? 1, s = H(e, 0);
  s.setFullYear(r + 1, 0, o), s.setHours(0, 0, 0, 0);
  const i = Z(s, t), c = H(e, 0);
  c.setFullYear(r, 0, o), c.setHours(0, 0, 0, 0);
  const l = Z(c, t);
  return n.getTime() >= i.getTime() ? r + 1 : n.getTime() >= l.getTime() ? r : r - 1;
}
function Zn(e, t) {
  var i, c, l, f;
  const n = se(), r = (t == null ? void 0 : t.firstWeekContainsDate) ?? ((c = (i = t == null ? void 0 : t.locale) == null ? void 0 : i.options) == null ? void 0 : c.firstWeekContainsDate) ?? n.firstWeekContainsDate ?? ((f = (l = n.locale) == null ? void 0 : l.options) == null ? void 0 : f.firstWeekContainsDate) ?? 1, a = Le(e, t), o = H(e, 0);
  return o.setFullYear(a, 0, r), o.setHours(0, 0, 0, 0), Z(o, t);
}
function Qe(e, t) {
  const n = O(e), r = +Z(n, t) - +Zn(n, t);
  return Math.round(r / pe) + 1;
}
function W(e, t) {
  const n = e < 0 ? "-" : "", r = Math.abs(e).toString().padStart(t, "0");
  return n + r;
}
const L = {
  // Year
  y(e, t) {
    const n = e.getFullYear(), r = n > 0 ? n : 1 - n;
    return W(t === "yy" ? r % 100 : r, t.length);
  },
  // Month
  M(e, t) {
    const n = e.getMonth();
    return t === "M" ? String(n + 1) : W(n + 1, 2);
  },
  // Day of the month
  d(e, t) {
    return W(e.getDate(), t.length);
  },
  // AM or PM
  a(e, t) {
    const n = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return n.toUpperCase();
      case "aaa":
        return n;
      case "aaaaa":
        return n[0];
      case "aaaa":
      default:
        return n === "am" ? "a.m." : "p.m.";
    }
  },
  // Hour [1-12]
  h(e, t) {
    return W(e.getHours() % 12 || 12, t.length);
  },
  // Hour [0-23]
  H(e, t) {
    return W(e.getHours(), t.length);
  },
  // Minute
  m(e, t) {
    return W(e.getMinutes(), t.length);
  },
  // Second
  s(e, t) {
    return W(e.getSeconds(), t.length);
  },
  // Fraction of second
  S(e, t) {
    const n = t.length, r = e.getMilliseconds(), a = Math.trunc(
      r * Math.pow(10, n - 3)
    );
    return W(a, t.length);
  }
}, z = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
}, Te = {
  // Era
  G: function(e, t, n) {
    const r = e.getFullYear() > 0 ? 1 : 0;
    switch (t) {
      case "G":
      case "GG":
      case "GGG":
        return n.era(r, { width: "abbreviated" });
      case "GGGGG":
        return n.era(r, { width: "narrow" });
      case "GGGG":
      default:
        return n.era(r, { width: "wide" });
    }
  },
  // Year
  y: function(e, t, n) {
    if (t === "yo") {
      const r = e.getFullYear(), a = r > 0 ? r : 1 - r;
      return n.ordinalNumber(a, { unit: "year" });
    }
    return L.y(e, t);
  },
  // Local week-numbering year
  Y: function(e, t, n, r) {
    const a = Le(e, r), o = a > 0 ? a : 1 - a;
    if (t === "YY") {
      const s = o % 100;
      return W(s, 2);
    }
    return t === "Yo" ? n.ordinalNumber(o, { unit: "year" }) : W(o, t.length);
  },
  // ISO week-numbering year
  R: function(e, t) {
    const n = Re(e);
    return W(n, t.length);
  },
  // Extended year. This is a single number designating the year of this calendar system.
  // The main difference between `y` and `u` localizers are B.C. years:
  // | Year | `y` | `u` |
  // |------|-----|-----|
  // | AC 1 |   1 |   1 |
  // | BC 1 |   1 |   0 |
  // | BC 2 |   2 |  -1 |
  // Also `yy` always returns the last two digits of a year,
  // while `uu` pads single digit years to 2 characters and returns other years unchanged.
  u: function(e, t) {
    const n = e.getFullYear();
    return W(n, t.length);
  },
  // Quarter
  Q: function(e, t, n) {
    const r = Math.ceil((e.getMonth() + 1) / 3);
    switch (t) {
      case "Q":
        return String(r);
      case "QQ":
        return W(r, 2);
      case "Qo":
        return n.ordinalNumber(r, { unit: "quarter" });
      case "QQQ":
        return n.quarter(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "QQQQQ":
        return n.quarter(r, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return n.quarter(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone quarter
  q: function(e, t, n) {
    const r = Math.ceil((e.getMonth() + 1) / 3);
    switch (t) {
      case "q":
        return String(r);
      case "qq":
        return W(r, 2);
      case "qo":
        return n.ordinalNumber(r, { unit: "quarter" });
      case "qqq":
        return n.quarter(r, {
          width: "abbreviated",
          context: "standalone"
        });
      case "qqqqq":
        return n.quarter(r, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return n.quarter(r, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Month
  M: function(e, t, n) {
    const r = e.getMonth();
    switch (t) {
      case "M":
      case "MM":
        return L.M(e, t);
      case "Mo":
        return n.ordinalNumber(r + 1, { unit: "month" });
      case "MMM":
        return n.month(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "MMMMM":
        return n.month(r, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return n.month(r, { width: "wide", context: "formatting" });
    }
  },
  // Stand-alone month
  L: function(e, t, n) {
    const r = e.getMonth();
    switch (t) {
      case "L":
        return String(r + 1);
      case "LL":
        return W(r + 1, 2);
      case "Lo":
        return n.ordinalNumber(r + 1, { unit: "month" });
      case "LLL":
        return n.month(r, {
          width: "abbreviated",
          context: "standalone"
        });
      case "LLLLL":
        return n.month(r, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return n.month(r, { width: "wide", context: "standalone" });
    }
  },
  // Local week of year
  w: function(e, t, n, r) {
    const a = Qe(e, r);
    return t === "wo" ? n.ordinalNumber(a, { unit: "week" }) : W(a, t.length);
  },
  // ISO week of year
  I: function(e, t, n) {
    const r = Ge(e);
    return t === "Io" ? n.ordinalNumber(r, { unit: "week" }) : W(r, t.length);
  },
  // Day of the month
  d: function(e, t, n) {
    return t === "do" ? n.ordinalNumber(e.getDate(), { unit: "date" }) : L.d(e, t);
  },
  // Day of year
  D: function(e, t, n) {
    const r = zn(e);
    return t === "Do" ? n.ordinalNumber(r, { unit: "dayOfYear" }) : W(r, t.length);
  },
  // Day of week
  E: function(e, t, n) {
    const r = e.getDay();
    switch (t) {
      case "E":
      case "EE":
      case "EEE":
        return n.day(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "EEEEE":
        return n.day(r, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return n.day(r, {
          width: "short",
          context: "formatting"
        });
      case "EEEE":
      default:
        return n.day(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Local day of week
  e: function(e, t, n, r) {
    const a = e.getDay(), o = (a - r.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "e":
        return String(o);
      case "ee":
        return W(o, 2);
      case "eo":
        return n.ordinalNumber(o, { unit: "day" });
      case "eee":
        return n.day(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "eeeee":
        return n.day(a, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return n.day(a, {
          width: "short",
          context: "formatting"
        });
      case "eeee":
      default:
        return n.day(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone local day of week
  c: function(e, t, n, r) {
    const a = e.getDay(), o = (a - r.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "c":
        return String(o);
      case "cc":
        return W(o, t.length);
      case "co":
        return n.ordinalNumber(o, { unit: "day" });
      case "ccc":
        return n.day(a, {
          width: "abbreviated",
          context: "standalone"
        });
      case "ccccc":
        return n.day(a, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return n.day(a, {
          width: "short",
          context: "standalone"
        });
      case "cccc":
      default:
        return n.day(a, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // ISO day of week
  i: function(e, t, n) {
    const r = e.getDay(), a = r === 0 ? 7 : r;
    switch (t) {
      case "i":
        return String(a);
      case "ii":
        return W(a, t.length);
      case "io":
        return n.ordinalNumber(a, { unit: "day" });
      case "iii":
        return n.day(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "iiiii":
        return n.day(r, {
          width: "narrow",
          context: "formatting"
        });
      case "iiiiii":
        return n.day(r, {
          width: "short",
          context: "formatting"
        });
      case "iiii":
      default:
        return n.day(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM or PM
  a: function(e, t, n) {
    const a = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return n.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return n.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM, PM, midnight, noon
  b: function(e, t, n) {
    const r = e.getHours();
    let a;
    switch (r === 12 ? a = z.noon : r === 0 ? a = z.midnight : a = r / 12 >= 1 ? "pm" : "am", t) {
      case "b":
      case "bb":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return n.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return n.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // in the morning, in the afternoon, in the evening, at night
  B: function(e, t, n) {
    const r = e.getHours();
    let a;
    switch (r >= 17 ? a = z.evening : r >= 12 ? a = z.afternoon : r >= 4 ? a = z.morning : a = z.night, t) {
      case "B":
      case "BB":
      case "BBB":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return n.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return n.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Hour [1-12]
  h: function(e, t, n) {
    if (t === "ho") {
      let r = e.getHours() % 12;
      return r === 0 && (r = 12), n.ordinalNumber(r, { unit: "hour" });
    }
    return L.h(e, t);
  },
  // Hour [0-23]
  H: function(e, t, n) {
    return t === "Ho" ? n.ordinalNumber(e.getHours(), { unit: "hour" }) : L.H(e, t);
  },
  // Hour [0-11]
  K: function(e, t, n) {
    const r = e.getHours() % 12;
    return t === "Ko" ? n.ordinalNumber(r, { unit: "hour" }) : W(r, t.length);
  },
  // Hour [1-24]
  k: function(e, t, n) {
    let r = e.getHours();
    return r === 0 && (r = 24), t === "ko" ? n.ordinalNumber(r, { unit: "hour" }) : W(r, t.length);
  },
  // Minute
  m: function(e, t, n) {
    return t === "mo" ? n.ordinalNumber(e.getMinutes(), { unit: "minute" }) : L.m(e, t);
  },
  // Second
  s: function(e, t, n) {
    return t === "so" ? n.ordinalNumber(e.getSeconds(), { unit: "second" }) : L.s(e, t);
  },
  // Fraction of second
  S: function(e, t) {
    return L.S(e, t);
  },
  // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
  X: function(e, t, n) {
    const r = e.getTimezoneOffset();
    if (r === 0)
      return "Z";
    switch (t) {
      case "X":
        return Fe(r);
      case "XXXX":
      case "XX":
        return X(r);
      case "XXXXX":
      case "XXX":
      default:
        return X(r, ":");
    }
  },
  // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
  x: function(e, t, n) {
    const r = e.getTimezoneOffset();
    switch (t) {
      case "x":
        return Fe(r);
      case "xxxx":
      case "xx":
        return X(r);
      case "xxxxx":
      case "xxx":
      default:
        return X(r, ":");
    }
  },
  // Timezone (GMT)
  O: function(e, t, n) {
    const r = e.getTimezoneOffset();
    switch (t) {
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + _e(r, ":");
      case "OOOO":
      default:
        return "GMT" + X(r, ":");
    }
  },
  // Timezone (specific non-location)
  z: function(e, t, n) {
    const r = e.getTimezoneOffset();
    switch (t) {
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + _e(r, ":");
      case "zzzz":
      default:
        return "GMT" + X(r, ":");
    }
  },
  // Seconds timestamp
  t: function(e, t, n) {
    const r = Math.trunc(e.getTime() / 1e3);
    return W(r, t.length);
  },
  // Milliseconds timestamp
  T: function(e, t, n) {
    const r = e.getTime();
    return W(r, t.length);
  }
};
function _e(e, t = "") {
  const n = e > 0 ? "-" : "+", r = Math.abs(e), a = Math.trunc(r / 60), o = r % 60;
  return o === 0 ? n + String(a) : n + String(a) + t + W(o, 2);
}
function Fe(e, t) {
  return e % 60 === 0 ? (e > 0 ? "-" : "+") + W(Math.abs(e) / 60, 2) : X(e, t);
}
function X(e, t = "") {
  const n = e > 0 ? "-" : "+", r = Math.abs(e), a = W(Math.trunc(r / 60), 2), o = W(r % 60, 2);
  return n + a + t + o;
}
const Be = (e, t) => {
  switch (e) {
    case "P":
      return t.date({ width: "short" });
    case "PP":
      return t.date({ width: "medium" });
    case "PPP":
      return t.date({ width: "long" });
    case "PPPP":
    default:
      return t.date({ width: "full" });
  }
}, Xe = (e, t) => {
  switch (e) {
    case "p":
      return t.time({ width: "short" });
    case "pp":
      return t.time({ width: "medium" });
    case "ppp":
      return t.time({ width: "long" });
    case "pppp":
    default:
      return t.time({ width: "full" });
  }
}, Un = (e, t) => {
  const n = e.match(/(P+)(p+)?/) || [], r = n[1], a = n[2];
  if (!a)
    return Be(e, t);
  let o;
  switch (r) {
    case "P":
      o = t.dateTime({ width: "short" });
      break;
    case "PP":
      o = t.dateTime({ width: "medium" });
      break;
    case "PPP":
      o = t.dateTime({ width: "long" });
      break;
    case "PPPP":
    default:
      o = t.dateTime({ width: "full" });
      break;
  }
  return o.replace("{{date}}", Be(r, t)).replace("{{time}}", Xe(a, t));
}, er = {
  p: Xe,
  P: Un
}, tr = /^D+$/, nr = /^Y+$/, rr = ["D", "DD", "YY", "YYYY"];
function ar(e) {
  return tr.test(e);
}
function or(e) {
  return nr.test(e);
}
function sr(e, t, n) {
  const r = ir(e, t, n);
  if (console.warn(r), rr.includes(e))
    throw new RangeError(r);
}
function ir(e, t, n) {
  const r = e[0] === "Y" ? "years" : "days of the month";
  return `Use \`${e.toLowerCase()}\` instead of \`${e}\` (in \`${t}\`) for formatting ${r} to the input \`${n}\`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md`;
}
function Ve(e) {
  return e instanceof Date || typeof e == "object" && Object.prototype.toString.call(e) === "[object Date]";
}
function cr(e) {
  if (!Ve(e) && typeof e != "number")
    return !1;
  const t = O(e);
  return !isNaN(Number(t));
}
const ur = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, dr = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, fr = /^'([^]*?)'?$/, lr = /''/g, hr = /[a-zA-Z]/;
function mr(e, t, n) {
  var f, u, g, b, w, M, m, k;
  const r = se(), a = (n == null ? void 0 : n.locale) ?? r.locale ?? De, o = (n == null ? void 0 : n.firstWeekContainsDate) ?? ((u = (f = n == null ? void 0 : n.locale) == null ? void 0 : f.options) == null ? void 0 : u.firstWeekContainsDate) ?? r.firstWeekContainsDate ?? ((b = (g = r.locale) == null ? void 0 : g.options) == null ? void 0 : b.firstWeekContainsDate) ?? 1, s = (n == null ? void 0 : n.weekStartsOn) ?? ((M = (w = n == null ? void 0 : n.locale) == null ? void 0 : w.options) == null ? void 0 : M.weekStartsOn) ?? r.weekStartsOn ?? ((k = (m = r.locale) == null ? void 0 : m.options) == null ? void 0 : k.weekStartsOn) ?? 0, i = O(e);
  if (!cr(i))
    throw new RangeError("Invalid time value");
  let c = t.match(dr).map((D) => {
    const d = D[0];
    if (d === "p" || d === "P") {
      const Y = er[d];
      return Y(D, a.formatLong);
    }
    return D;
  }).join("").match(ur).map((D) => {
    if (D === "''")
      return { isToken: !1, value: "'" };
    const d = D[0];
    if (d === "'")
      return { isToken: !1, value: gr(D) };
    if (Te[d])
      return { isToken: !0, value: D };
    if (d.match(hr))
      throw new RangeError(
        "Format string contains an unescaped latin alphabet character `" + d + "`"
      );
    return { isToken: !1, value: D };
  });
  a.localize.preprocessor && (c = a.localize.preprocessor(i, c));
  const l = {
    firstWeekContainsDate: o,
    weekStartsOn: s,
    locale: a
  };
  return c.map((D) => {
    if (!D.isToken)
      return D.value;
    const d = D.value;
    (!(n != null && n.useAdditionalWeekYearTokens) && or(d) || !(n != null && n.useAdditionalDayOfYearTokens) && ar(d)) && sr(d, t, String(e));
    const Y = Te[d[0]];
    return Y(i, d, a.localize, l);
  }).join("");
}
function gr(e) {
  const t = e.match(fr);
  return t ? t[1].replace(lr, "'") : e;
}
function yr(e, t) {
  const n = O(e), r = O(t);
  return n.getTime() > r.getTime();
}
function wr(e, t) {
  const n = O(e), r = O(t);
  return +n < +r;
}
function br(e, t) {
  const n = ae(e), r = ae(t);
  return +n == +r;
}
function Mr(e, t) {
  const n = O(e), r = O(t);
  return n.getFullYear() === r.getFullYear() && n.getMonth() === r.getMonth();
}
function kr(e, t) {
  const n = O(e), r = O(t);
  return n.getFullYear() === r.getFullYear();
}
function Dr(e) {
  let t;
  return e.forEach(function(n) {
    const r = O(n);
    (t === void 0 || t < r || isNaN(Number(r))) && (t = r);
  }), t || /* @__PURE__ */ new Date(NaN);
}
function Or(e) {
  let t;
  return e.forEach((n) => {
    const r = O(n);
    (!t || t > r || isNaN(+r)) && (t = r);
  }), t || /* @__PURE__ */ new Date(NaN);
}
function Wr(e) {
  const t = O(e), n = t.getFullYear(), r = t.getMonth(), a = H(e, 0);
  return a.setFullYear(n, r + 1, 0), a.setHours(0, 0, 0, 0), a.getDate();
}
function Nr(e, t) {
  const n = O(e), r = n.getFullYear(), a = n.getDate(), o = H(e, 0);
  o.setFullYear(r, t, 15), o.setHours(0, 0, 0, 0);
  const s = Wr(o);
  return n.setMonth(t, Math.min(a, s)), n;
}
function vr(e, t) {
  const n = O(e);
  return isNaN(+n) ? H(e, NaN) : (n.setFullYear(t), n);
}
function Pr(e) {
  const t = O(e);
  return t.setDate(1), t.setHours(0, 0, 0, 0), t;
}
const p = {
  /** The constructor of the date object. */
  Date,
  addDays: qe,
  addMonths: He,
  addWeeks: dn,
  addYears: fn,
  differenceInCalendarDays: Ie,
  differenceInCalendarMonths: hn,
  endOfISOWeek: gn,
  endOfMonth: yn,
  endOfWeek: je,
  endOfYear: wn,
  format: mr,
  getISOWeek: Ge,
  getWeek: Qe,
  isAfter: yr,
  isBefore: wr,
  isDate: Ve,
  isSameDay: br,
  isSameMonth: Mr,
  isSameYear: kr,
  max: Dr,
  min: Or,
  setMonth: Nr,
  setYear: vr,
  startOfDay: ae,
  startOfISOWeek: oe,
  startOfMonth: Pr,
  startOfWeek: Z,
  startOfYear: Ae
};
function Sr(e) {
  return {
    ...p,
    ...e
  };
}
function Yr() {
  const e = {};
  for (const t in y)
    e[y[t]] = `rdp-${y[t]}`;
  for (const t in S)
    e[S[t]] = `rdp-${S[t]}`;
  for (const t in _)
    e[_[t]] = `rdp-${_[t]}`;
  return e;
}
function $e(e, t, n = p) {
  return n.format(e, "LLLL y", t);
}
const xr = $e;
function Cr(e, t, n = p) {
  return n.format(e, "d", t);
}
function Er(e, t = De) {
  var n;
  return (n = t.localize) == null ? void 0 : n.month(e);
}
function Tr(e) {
  return e < 10 ? `0${e.toLocaleString()}` : `${e.toLocaleString()}`;
}
function _r() {
  return "";
}
function Fr(e, t, n = p) {
  return n.format(e, "cccccc", t);
}
function Je(e) {
  return e.toString();
}
const Br = Je, qr = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  formatCaption: $e,
  formatDay: Cr,
  formatMonthCaption: xr,
  formatMonthDropdown: Er,
  formatWeekNumber: Tr,
  formatWeekNumberHeader: _r,
  formatWeekdayName: Fr,
  formatYearCaption: Br,
  formatYearDropdown: Je
}, Symbol.toStringTag, { value: "Module" }));
function Hr(e) {
  return e != null && e.formatMonthCaption && !e.formatCaption && (e.formatCaption = e.formatMonthCaption), e != null && e.formatYearCaption && !e.formatYearDropdown && (e.formatYearDropdown = e.formatYearCaption), {
    ...qr,
    ...e
  };
}
function pr(e, t, n, r, a, o) {
  if (!t || !n)
    return;
  const { addMonths: s, startOfMonth: i, isBefore: c, Date: l } = o, f = e.getFullYear(), u = [];
  let g = t;
  for (; u.length < 12 && c(g, s(n, 1)); )
    u.push(g.getMonth()), g = s(g, 1);
  return u.sort((M, m) => M - m).map((M) => {
    const m = r.formatMonthDropdown(M, a), k = t && new l(f, M) < i(t) || n && new l(f, M) > i(n) || !1;
    return { value: M, label: m, disabled: k };
  });
}
function Ir(e, t = {}, n = {}) {
  let r = { ...t == null ? void 0 : t[y.Day] };
  return Object.entries(e).filter(([, a]) => a === !0).forEach(([a]) => {
    r = {
      ...r,
      ...n == null ? void 0 : n[a]
    };
  }), r;
}
function jr(e, t, n, r = p) {
  const a = n ? r.startOfISOWeek(new r.Date()) : r.startOfWeek(new r.Date(), { locale: e, weekStartsOn: t }), o = [];
  for (let s = 0; s < 7; s++) {
    const i = r.addDays(a, s);
    o.push(i);
  }
  return o;
}
function Ar(e, t, n, r, a) {
  if (!t || !n)
    return;
  const { startOfMonth: o, startOfYear: s, endOfYear: i, addYears: c, isBefore: l, isSameYear: f, Date: u } = a, g = e.getMonth(), b = s(t), w = i(n), M = [];
  let m = b;
  for (; l(m, w) || f(m, w); )
    M.push(m.getFullYear()), m = c(m, 1);
  return M.map((k) => {
    const D = t && new u(k, g) < o(t) || g && n && new u(k, g) > o(n) || !1, d = r.formatYearDropdown(k);
    return {
      value: k,
      label: d,
      disabled: D
    };
  });
}
function ze(e, t, n = p) {
  return n.format(e, "LLLL y", t);
}
const Rr = ze;
function Gr(e, t, n, r = p) {
  let a = r.format(e, "PPPP", n);
  return t != null && t.today && (a = `Today, ${a}`), a;
}
function Ke(e, t, n, r = p) {
  let a = r.format(e, "PPPP", n);
  return t.today && (a = `Today, ${a}`), t.selected && (a = `${a}, selected`), a;
}
const Lr = Ke;
function Qr() {
  return "";
}
function Xr(e) {
  return "Choose the Month";
}
function Vr(e, t) {
  return "Go to the Next Month";
}
function $r(e, t) {
  return "Go to the Previous Month";
}
function Jr(e, t, n = p) {
  return n.format(e, "cccc", t);
}
function zr(e, t) {
  return `Week ${e}`;
}
function Kr(e) {
  return "Week Number";
}
function Zr(e) {
  return "Choose the Year";
}
const Ur = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  labelCaption: Rr,
  labelDay: Lr,
  labelDayButton: Ke,
  labelGrid: ze,
  labelGridcell: Gr,
  labelMonthDropdown: Xr,
  labelNav: Qr,
  labelNext: Vr,
  labelPrevious: $r,
  labelWeekNumber: zr,
  labelWeekNumberHeader: Kr,
  labelWeekday: Jr,
  labelYearDropdown: Zr
}, Symbol.toStringTag, { value: "Module" })), ea = 42;
function ta(e, t, n, r) {
  const a = e[0], o = e[e.length - 1], { ISOWeek: s, fixedWeeks: i, locale: c, weekStartsOn: l } = n ?? {}, { startOfWeek: f, endOfWeek: u, startOfISOWeek: g, endOfISOWeek: b, addDays: w, differenceInCalendarDays: M, differenceInCalendarMonths: m, isAfter: k, endOfMonth: D, Date: d } = r, Y = s ? g(a) : f(a, {
    weekStartsOn: l,
    locale: c
  }), x = s ? b(D(o)) : u(D(o), {
    weekStartsOn: l,
    locale: c
  }), P = M(x, Y), E = m(o, a) + 1, T = [];
  for (let A = 0; A <= P; A++) {
    const V = w(Y, A);
    if (t && k(V, t))
      break;
    T.push(new d(V));
  }
  const U = ea * E;
  if (i && T.length < U)
    for (let A = 0; A < 7; A++) {
      const V = w(T[T.length - 1], 1);
      T.push(new d(V));
    }
  return T;
}
function na(e) {
  const t = [];
  return e.reduce((n, r) => {
    const a = [], o = r.weeks.reduce((s, i) => [...s, ...i.days], a);
    return [...n, ...o];
  }, t);
}
function ra(e, t, n, r) {
  const { numberOfMonths: a = 1 } = n, o = [];
  for (let s = 0; s < a; s++) {
    const i = r.addMonths(e, s);
    if (t && i > t)
      break;
    o.push(i);
  }
  return o;
}
function Me(e, t) {
  const { month: n, defaultMonth: r, today: a = new t.Date(), numberOfMonths: o = 1, endMonth: s, startMonth: i } = e;
  let c = n || r || a;
  const { differenceInCalendarMonths: l, addMonths: f, startOfMonth: u } = t;
  if (s && l(s, c) < 0) {
    const g = -1 * (o - 1);
    c = f(s, g);
  }
  return i && l(c, i) < 0 && (c = i), u(c);
}
class Ze {
  constructor(t, n, r = p) {
    this.date = t, this.displayMonth = n, this.outside = !!(n && !r.isSameMonth(t, n)), this.dateLib = r;
  }
  /**
   * Check if the day is the same as the given day: considering if it is in the
   * same display month.
   */
  isEqualTo(t) {
    return this.dateLib.isSameDay(t.date, this.date) && this.dateLib.isSameMonth(t.displayMonth, this.displayMonth);
  }
}
class aa {
  constructor(t, n) {
    this.date = t, this.weeks = n;
  }
}
class oa {
  constructor(t, n) {
    this.days = n, this.weekNumber = t;
  }
}
function sa(e, t, n, r) {
  const { startOfWeek: a, endOfWeek: o, startOfISOWeek: s, endOfISOWeek: i, endOfMonth: c, addDays: l, getWeek: f, getISOWeek: u } = r, g = e.reduce((b, w) => {
    const M = n.ISOWeek ? s(w) : a(w, {
      locale: n.locale,
      weekStartsOn: n.weekStartsOn
    }), m = n.ISOWeek ? i(c(w)) : o(c(w), {
      locale: n.locale,
      weekStartsOn: n.weekStartsOn
    }), k = t.filter((Y) => Y >= M && Y <= m);
    if (n.fixedWeeks && k.length < 42) {
      const Y = t.filter((x) => x > m && x <= l(m, 7));
      k.push(...Y);
    }
    const D = k.reduce((Y, x) => {
      const P = n.ISOWeek ? u(x) : f(x, {
        locale: n.locale,
        weekStartsOn: n.weekStartsOn,
        firstWeekContainsDate: n.firstWeekContainsDate
      }), E = Y.find((U) => U.weekNumber === P), T = new Ze(x, w, r);
      return E ? E.days.push(T) : Y.push(new oa(P, [T])), Y;
    }, []), d = new aa(w, D);
    return b.push(d), b;
  }, []);
  return n.reverseMonths ? g.reverse() : g;
}
function ia(e, t) {
  var m;
  let { startMonth: n, endMonth: r } = e;
  const { startOfYear: a, startOfDay: o, startOfMonth: s, endOfMonth: i, addYears: c, endOfYear: l, Date: f } = t, { fromYear: u, toYear: g, fromMonth: b, toMonth: w } = e;
  !n && b && (n = b), !n && u && (n = new f(u, 0, 1)), !r && w && (r = w), !r && g && (r = new f(g, 11, 31));
  const M = (m = e.captionLayout) == null ? void 0 : m.startsWith("dropdown");
  return n ? n = s(n) : u ? n = new f(u, 0, 1) : !n && M && (n = a(c(e.today ?? new f(), -100))), r ? r = i(r) : g ? r = new f(g, 11, 31) : !r && M && (r = l(e.today ?? new f())), [
    n && o(n),
    r && o(r)
  ];
}
function ca(e, t, n, r) {
  if (n.disableNavigation)
    return;
  const { pagedNavigation: a, numberOfMonths: o = 1 } = n, { startOfMonth: s, addMonths: i, differenceInCalendarMonths: c } = r, l = a ? o : 1, f = s(e);
  if (!t)
    return i(f, l);
  if (!(c(t, e) < o))
    return i(f, l);
}
function ua(e, t, n, r) {
  if (n.disableNavigation)
    return;
  const { pagedNavigation: a, numberOfMonths: o } = n, { startOfMonth: s, addMonths: i, differenceInCalendarMonths: c } = r, l = a ? o ?? 1 : 1, f = s(e);
  if (!t)
    return i(f, -l);
  if (!(c(f, t) <= 0))
    return i(f, -l);
}
function da(e) {
  const t = [];
  return e.reduce((n, r) => [...n, ...r.weeks], t);
}
function fa(e, t) {
  const [n, r] = ia(e, t), { startOfMonth: a, endOfMonth: o } = t, s = Me(e, t), [i, c] = ke(s);
  Ce(() => {
    const P = Me(e, t);
    c(P);
  }, [e.month]), Ce(() => {
    const P = Me(e, t);
    c(P);
  }, [e.startMonth, e.endMonth]);
  const l = ra(i, r, e, t), f = ta(l, e.endMonth ? o(e.endMonth) : void 0, e, t), u = sa(l, f, e, t), g = da(u), b = na(u), w = ua(i, n, e, t), M = ca(i, r, e, t), { disableNavigation: m, onMonthChange: k } = e, D = (P) => g.some((E) => E.days.some((T) => T.isEqualTo(P))), d = (P) => {
    if (m)
      return;
    let E = a(P);
    n && E < a(n) && (E = a(n)), r && E > a(r) && (E = a(r)), c(E), k == null || k(E);
  };
  return {
    months: u,
    weeks: g,
    days: b,
    navStart: n,
    navEnd: r,
    previousMonth: w,
    nextMonth: M,
    goToMonth: d,
    goToDay: (P) => {
      D(P) || d(P.date);
    }
  };
}
const la = Bt(void 0);
function ha(e, t, n, r) {
  let a, o = 0, s = !1;
  for (; o < e.length && !s; ) {
    const i = e[o], c = t(i);
    !c[S.disabled] && !c[S.hidden] && !c[S.outside] && (c[S.focused] || r != null && r.isEqualTo(i) || n(i.date) || c[S.today]) && (a = i, s = !0), o++;
  }
  return a || (a = e.find((i) => {
    const c = t(i);
    return !c[S.disabled] && !c[S.hidden] && !c[S.outside];
  })), a;
}
function Oe(e, t, n = !1, r = p) {
  let { from: a, to: o } = e;
  const { differenceInCalendarDays: s, isSameDay: i } = r;
  return a && o ? (s(o, a) < 0 && ([a, o] = [o, a]), s(t, a) >= (n ? 1 : 0) && s(o, t) >= (n ? 1 : 0)) : !n && o ? i(o, t) : !n && a ? i(a, t) : !1;
}
function ma(e) {
  return !!(e && typeof e == "object" && "before" in e && "after" in e);
}
function Ue(e) {
  return !!(e && typeof e == "object" && "from" in e);
}
function ga(e) {
  return !!(e && typeof e == "object" && "after" in e);
}
function ya(e) {
  return !!(e && typeof e == "object" && "before" in e);
}
function wa(e) {
  return !!(e && typeof e == "object" && "dayOfWeek" in e);
}
function ba(e, t) {
  return Array.isArray(e) && e.every(t.isDate);
}
function K(e, t, n) {
  const r = Array.isArray(t) ? t : [t], { isSameDay: a, differenceInCalendarDays: o, isAfter: s } = n;
  return r.some((i) => {
    if (typeof i == "boolean")
      return i;
    if (n.isDate(i))
      return a(e, i);
    if (ba(i, n))
      return i.includes(e);
    if (Ue(i))
      return Oe(i, e, !1, n);
    if (wa(i))
      return Array.isArray(i.dayOfWeek) ? i.dayOfWeek.includes(e.getDay()) : i.dayOfWeek === e.getDay();
    if (ma(i)) {
      const c = o(i.before, e), l = o(i.after, e), f = c > 0, u = l < 0;
      return s(i.before, i.after) ? u && f : f || u;
    }
    return ga(i) ? o(e, i.after) > 0 : ya(i) ? o(i.before, e) > 0 : typeof i == "function" ? i(e) : !1;
  });
}
function Ma(e, t, n, r, a, o, s) {
  const { weekStartsOn: i, locale: c, ISOWeek: l } = o, { addDays: f, addMonths: u, addYears: g, addWeeks: b, startOfISOWeek: w, endOfISOWeek: M, startOfWeek: m, endOfWeek: k, max: D, min: d } = s;
  let x = {
    day: f,
    week: b,
    month: u,
    year: g,
    startOfWeek: (P) => l ? w(P) : m(P, { locale: c, weekStartsOn: i }),
    endOfWeek: (P) => l ? M(P) : k(P, { locale: c, weekStartsOn: i })
  }[e](n, t === "after" ? 1 : -1);
  return t === "before" && r ? x = D([r, x]) : t === "after" && a && (x = d([a, x])), x;
}
function et(e, t, n, r, a, o, s, i = 0) {
  if (i > 365)
    return;
  const c = Ma(
    e,
    t,
    n.date,
    // should be refDay? or refDay.date?
    r,
    a,
    o,
    s
  ), l = !!(o.disabled && K(c, o.disabled, s)), f = !!(o.hidden && K(c, o.hidden, s)), u = c, g = new Ze(c, u, s);
  return !l && !f ? g : et(e, t, g, r, a, o, s, i + 1);
}
function ka(e, t, n, r, a) {
  const { autoFocus: o } = e, [s, i] = ke(), c = ha(t.days, n, r || (() => !1), s), [l, f] = ke(o ? c : void 0);
  return {
    isFocusTarget: (M) => !!(c != null && c.isEqualTo(M)),
    setFocused: f,
    focused: l,
    blur: () => {
      i(l), f(void 0);
    },
    moveFocus: (M, m) => {
      if (!l)
        return;
      const k = et(M, m, l, t.navStart, t.navEnd, e, a);
      k && (t.goToDay(k), f(k));
    }
  };
}
function Da(e, t, n) {
  const { disabled: r, hidden: a, modifiers: o, showOutsideDays: s, today: i } = t, { isSameDay: c, isSameMonth: l, Date: f } = n, u = {
    [S.focused]: [],
    [S.outside]: [],
    [S.disabled]: [],
    [S.hidden]: [],
    [S.today]: []
  }, g = {}, b = {
    [_.range_end]: [],
    [_.range_middle]: [],
    [_.range_start]: [],
    [_.selected]: []
  };
  for (const w of e) {
    const { date: M, displayMonth: m } = w, k = !!(m && !l(M, m)), D = !!(r && K(M, r, n)), d = !!(a && K(M, a, n)) || !s && k, Y = c(M, i ?? new f());
    k && u.outside.push(w), D && u.disabled.push(w), d && u.hidden.push(w), Y && u.today.push(w), o && Object.keys(o).forEach((x) => {
      const P = o == null ? void 0 : o[x];
      P && K(M, P, n) && (g[x] ? g[x].push(w) : g[x] = [w]);
    });
  }
  return (w) => {
    const M = {
      [S.focused]: !1,
      [S.disabled]: !1,
      [S.hidden]: !1,
      [S.outside]: !1,
      [S.today]: !1
    }, m = {
      [_.range_end]: !1,
      [_.range_middle]: !1,
      [_.range_start]: !1,
      [_.selected]: !1
    }, k = {};
    for (const D in u) {
      const d = u[D];
      M[D] = d.some((Y) => Y === w);
    }
    for (const D in b) {
      const d = b[D];
      m[D] = d.some((Y) => Y === w);
    }
    for (const D in g)
      k[D] = g[D].some((d) => d === w);
    return {
      ...m,
      ...M,
      // custom modifiers should override all the previous ones
      ...k
    };
  };
}
function Oa(e, t) {
  const { selected: n, required: r, onSelect: a, mode: o } = e, [s, i] = h.useState(n), { isSameDay: c } = t;
  h.useEffect(() => {
    i(n);
  }, [o, n]);
  const l = (b) => (s == null ? void 0 : s.some((w) => c(w, b))) ?? !1, { min: f, max: u } = e;
  return {
    selected: s,
    select: (b, w, M) => {
      let m = [...s ?? []];
      if (l(b)) {
        if ((s == null ? void 0 : s.length) === f || r && (s == null ? void 0 : s.length) === 1)
          return;
        m = s == null ? void 0 : s.filter((k) => !c(k, b));
      } else
        (s == null ? void 0 : s.length) === u ? m = [b] : m = [...m, b];
      return a == null || a(m, b, w, M), i(m), m;
    },
    isSelected: l
  };
}
function Wa(e, t, n = 0, r = 0, a = !1, o = p) {
  const { from: s, to: i } = t || {}, { isSameDay: c, isAfter: l, isBefore: f } = o;
  let u;
  if (!s && !i)
    u = { from: e, to: n > 0 ? void 0 : e };
  else if (s && !i)
    c(s, e) ? a ? u = { from: s, to: void 0 } : u = void 0 : f(e, s) ? u = { from: e, to: s } : u = { from: s, to: e };
  else if (s && i)
    if (c(s, e) && c(i, e))
      a ? u = { from: s, to: i } : u = void 0;
    else if (c(s, e))
      u = { from: s, to: n > 0 ? void 0 : e };
    else if (c(i, e))
      u = { from: e, to: n > 0 ? void 0 : e };
    else if (f(e, s))
      u = { from: e, to: i };
    else if (l(e, s))
      u = { from: s, to: e };
    else if (l(e, i))
      u = { from: s, to: e };
    else
      throw new Error("Invalid range");
  if (u != null && u.from && (u != null && u.to)) {
    const g = o.differenceInCalendarDays(u.to, u.from);
    r > 0 && g > r ? u = { from: e, to: void 0 } : n > 1 && g < n && (u = { from: e, to: void 0 });
  }
  return u;
}
function Na(e, t) {
  const { disabled: n, excludeDisabled: r, selected: a, required: o, onSelect: s } = e, [i, c] = h.useState(a);
  return h.useEffect(() => {
    c(a);
  }, [a]), {
    selected: i,
    select: (u, g, b) => {
      const { min: w, max: M } = e, m = u ? Wa(u, i, w, M, o, t) : void 0;
      if (m != null && m.from && m.to) {
        let k = m.from;
        for (; t.differenceInCalendarDays(m.to, k) > 0; )
          if (k = t.addDays(k, 1), r && n && K(k, n, t)) {
            m.from = u, m.to = void 0;
            break;
          }
      }
      return c(m), s == null || s(m, u, g, b), m;
    },
    isSelected: (u) => i && Oe(i, u, !1, t)
  };
}
function va(e, t) {
  const { selected: n, required: r, onSelect: a } = e, [o, s] = h.useState(n), { isSameDay: i } = t;
  return h.useEffect(() => {
    s(n);
  }, [n]), {
    selected: o,
    select: (f, u, g) => {
      let b = f;
      return !r && o && o && i(f, o) && (b = void 0), s(b), a == null || a(b, f, u, g), b;
    },
    isSelected: (f) => o ? i(o, f) : !1
  };
}
function Pa(e, t) {
  const n = va(e, t), r = Oa(e, t), a = Na(e, t);
  switch (e.mode) {
    case "single":
      return n;
    case "multiple":
      return r;
    case "range":
      return a;
    default:
      return;
  }
}
function Sa(e) {
  const { components: t, formatters: n, labels: r, dateLib: a, locale: o, classNames: s } = we(() => ({
    dateLib: Sr(e.dateLib),
    components: cn(e.components),
    formatters: Hr(e.formatters),
    labels: { ...Ur, ...e.labels },
    locale: { ...De, ...e.locale },
    classNames: { ...Yr(), ...e.classNames }
  }), [
    e.classNames,
    e.components,
    e.dateLib,
    e.formatters,
    e.labels,
    e.locale
  ]), { captionLayout: i, firstWeekContainsDate: c, mode: l, onDayBlur: f, onDayClick: u, onDayFocus: g, onDayKeyDown: b, onDayMouseEnter: w, onDayMouseLeave: M, onNextClick: m, onPrevClick: k, showWeekNumber: D, styles: d, useAdditionalDayOfYearTokens: Y, useAdditionalWeekYearTokens: x, weekStartsOn: P } = e, E = {
    locale: o,
    weekStartsOn: P,
    firstWeekContainsDate: c,
    useAdditionalWeekYearTokens: x,
    useAdditionalDayOfYearTokens: Y
  }, T = E, { formatCaption: U, formatDay: A, formatMonthDropdown: V, formatWeekNumber: tt, formatWeekNumberHeader: nt, formatWeekdayName: rt, formatYearDropdown: at } = n, We = fa(e, a), { days: ot, months: de, navStart: Ne, navEnd: ve, previousMonth: I, nextMonth: R, goToMonth: Q } = We, fe = Da(ot, e, a), { isSelected: ee, select: te, selected: ie } = Pa(e, a) ?? {}, { blur: Pe, focused: le, isFocusTarget: st, moveFocus: Se, setFocused: ce } = ka(e, We, fe, ee ?? (() => !1), a), { labelDayButton: it, labelGridcell: ct, labelGrid: ut, labelMonthDropdown: dt, labelNav: ft, labelNext: lt, labelPrevious: ht, labelWeekday: mt, labelWeekNumber: gt, labelWeekNumberHeader: yt, labelYearDropdown: wt } = r, bt = we(() => jr(o, e.weekStartsOn, e.ISOWeek, a), [a, o, e.ISOWeek, e.weekStartsOn]), Ye = l !== void 0 || u !== void 0, Mt = G(() => {
    I && (Q(I), k == null || k(I));
  }, [I, Q, k]), kt = G(() => {
    R && (Q(R), m == null || m(R));
  }, [Q, R, m]), Dt = G((v, B) => (C) => {
    C.preventDefault(), C.stopPropagation(), ce(v), te == null || te(v.date, B, C), u == null || u(v.date, B, C);
  }, [te, u, ce]), Ot = G((v, B) => (C) => {
    ce(v), g == null || g(v.date, B, C);
  }, [g, ce]), Wt = G((v, B) => (C) => {
    Pe(), f == null || f(v.date, B, C);
  }, [Pe, f]), Nt = G((v, B) => (C) => {
    const ue = {
      ArrowLeft: ["day", e.dir === "rtl" ? "after" : "before"],
      ArrowRight: ["day", e.dir === "rtl" ? "before" : "after"],
      ArrowDown: ["week", "after"],
      ArrowUp: ["week", "before"],
      PageUp: [C.shiftKey ? "year" : "month", "before"],
      PageDown: [C.shiftKey ? "year" : "month", "after"],
      Home: ["startOfWeek", "before"],
      End: ["endOfWeek", "after"]
    };
    if (ue[C.key]) {
      C.preventDefault(), C.stopPropagation();
      const [he, me] = ue[C.key];
      Se(he, me);
    }
    b == null || b(v.date, B, C);
  }, [Se, b, e.dir]), vt = G((v, B) => (C) => {
    w == null || w(v.date, B, C);
  }, [w]), Pt = G((v, B) => (C) => {
    M == null || M(v.date, B, C);
  }, [M]), { className: St, style: Yt } = we(() => ({
    className: [s[y.Root], e.className].filter(Boolean).join(" "),
    style: { ...d == null ? void 0 : d[y.Root], ...e.style }
  }), [s, e.className, e.style, d]), xt = un(e), Ct = {
    selected: ie,
    select: te,
    isSelected: ee,
    months: de,
    nextMonth: R,
    previousMonth: I,
    goToMonth: Q,
    getModifiers: fe
  };
  return h.createElement(
    la.Provider,
    { value: Ct },
    h.createElement(
      t.Root,
      { className: St, style: Yt, dir: e.dir, id: e.id, lang: e.lang, nonce: e.nonce, title: e.title, ...xt },
      h.createElement(
        t.Months,
        { className: s[y.Months], style: d == null ? void 0 : d[y.Months] },
        !e.hideNavigation && h.createElement(
          t.Nav,
          { role: "navigation", className: s[y.Nav], style: d == null ? void 0 : d[y.Nav], "aria-label": ft() },
          h.createElement(
            t.Button,
            { type: "button", className: s[y.ButtonPrevious], tabIndex: I ? void 0 : -1, disabled: I ? void 0 : !0, "aria-label": ht(I, T), onClick: Mt },
            h.createElement(t.Chevron, { disabled: I ? void 0 : !0, className: s[y.Chevron], orientation: "left" })
          ),
          h.createElement(
            t.Button,
            { type: "button", className: s[y.ButtonNext], tabIndex: R ? void 0 : -1, disabled: R ? void 0 : !0, "aria-label": lt(R, T), onClick: kt },
            h.createElement(t.Chevron, { disabled: I ? void 0 : !0, orientation: "right", className: s[y.Chevron] })
          )
        ),
        de.map((v, B) => {
          const C = (q) => {
            const $ = Number(q.target.value), F = a.setMonth(a.startOfMonth(v.date), $);
            Q(F);
          }, ue = (q) => {
            const $ = a.setYear(a.startOfMonth(v.date), Number(q.target.value));
            Q($);
          }, he = pr(v.date, Ne, ve, n, o, a), me = Ar(de[0].date, Ne, ve, n, a);
          return h.createElement(
            t.Month,
            { className: s[y.Month], style: d == null ? void 0 : d[y.Month], key: B, displayIndex: B, calendarMonth: v },
            h.createElement(t.MonthCaption, { className: s[y.MonthCaption], style: d == null ? void 0 : d[y.MonthCaption], calendarMonth: v, displayIndex: B }, i != null && i.startsWith("dropdown") ? h.createElement(
              t.DropdownNav,
              { className: s[y.Dropdowns], style: d == null ? void 0 : d[y.Dropdowns] },
              i === "dropdown" || i === "dropdown-months" ? h.createElement(t.Dropdown, { "aria-label": dt(), classNames: s, components: t, disabled: !!e.disableNavigation, onChange: C, options: he, style: d == null ? void 0 : d[y.Dropdown], value: v.date.getMonth() }) : h.createElement("span", { role: "status", "aria-live": "polite" }, V(v.date.getMonth())),
              i === "dropdown" || i === "dropdown-years" ? h.createElement(t.Dropdown, { "aria-label": wt(T), classNames: s, components: t, disabled: !!e.disableNavigation, onChange: ue, options: me, style: d == null ? void 0 : d[y.Dropdown], value: v.date.getFullYear() }) : h.createElement("span", { role: "status", "aria-live": "polite" }, at(v.date.getFullYear()))
            ) : h.createElement(t.CaptionLabel, { className: s[y.CaptionLabel], role: "status", "aria-live": "polite" }, U(v.date, E, a))),
            h.createElement(
              t.MonthGrid,
              { role: "grid", "aria-multiselectable": l === "multiple" || l === "range", "aria-label": ut(v.date, T, a) || void 0, className: s[y.MonthGrid], style: d == null ? void 0 : d[y.MonthGrid] },
              !e.hideWeekdays && h.createElement(
                t.Weekdays,
                { className: s[y.Weekdays], role: "row", style: d == null ? void 0 : d[y.Weekdays] },
                D && h.createElement(t.WeekNumberHeader, { "aria-label": yt(T), className: s[y.WeekNumberHeader], role: "columnheader", style: d == null ? void 0 : d[y.WeekNumberHeader] }, nt()),
                bt.map((q, $) => h.createElement(t.Weekday, { "aria-label": mt(q, T, a), className: s[y.Weekday], key: $, role: "columnheader", style: d == null ? void 0 : d[y.Weekday] }, rt(q, E, a)))
              ),
              h.createElement(t.Weeks, { className: s[y.Weeks], role: "rowgroup", style: d == null ? void 0 : d[y.Weeks] }, v.weeks.map((q, $) => h.createElement(
                t.Week,
                { className: s[y.Week], key: q.weekNumber, role: "row", style: d == null ? void 0 : d[y.Week], week: q },
                D && h.createElement(t.WeekNumber, { week: q, role: "rowheader", style: d == null ? void 0 : d[y.WeekNumber], "aria-label": gt(q.weekNumber, {
                  locale: o
                }), className: s[y.WeekNumber] }, tt(q.weekNumber)),
                q.days.map((F) => {
                  const { date: j } = F, N = fe(F);
                  if (N[S.focused] = !N.hidden && !!(le != null && le.isEqualTo(F)), N[_.selected] = !N.disabled && ((ee == null ? void 0 : ee(j)) || N.selected), Ue(ie)) {
                    const { from: ge, to: ye } = ie;
                    N[_.range_start] = !!(ge && ye && a.isSameDay(j, ge)), N[_.range_end] = !!(ge && ye && a.isSameDay(j, ye)), N[_.range_middle] = Oe(ie, j, !0, a);
                  }
                  const Et = Ir(N, d, e.modifiersStyles), Tt = Ht(N, s, e.modifiersClassNames), _t = Ye ? void 0 : ct(j, N, T, a);
                  return h.createElement(t.Day, { key: `${a.format(j, "yyyy-MM-dd")}_${a.format(F.displayMonth, "yyyy-MM")}`, day: F, modifiers: N, role: "gridcell", className: Tt.join(" "), style: Et, "aria-hidden": N.hidden || void 0, "aria-selected": N.selected || void 0, "aria-label": _t, "data-day": a.format(j, "yyyy-MM-dd"), "data-month": F.outside ? a.format(j, "yyyy-MM") : void 0, "data-selected": N.selected || void 0, "data-disabled": N.disabled || void 0, "data-hidden": N.hidden || void 0, "data-outside": F.outside || void 0, "data-focused": N.focused || void 0, "data-today": N.today || void 0 }, Ye ? h.createElement(t.DayButton, { className: s[y.DayButton], style: d == null ? void 0 : d[y.DayButton], day: F, modifiers: N, disabled: N.disabled || void 0, tabIndex: st(F) ? 0 : -1, "aria-label": it(j, N, T, a), onClick: Dt(F, N), onBlur: Wt(F, N), onFocus: Ot(F, N), onKeyDown: Nt(F, N), onMouseEnter: vt(F, N), onMouseLeave: Pt(F, N) }, A(j, E, a)) : A(F.date, E, a));
                })
              )))
            )
          );
        })
      ),
      e.footer && h.createElement(t.Footer, { className: s[y.Footer], style: d == null ? void 0 : d[y.Footer], role: "status", "aria-live": "polite" }, e.footer)
    )
  );
}
const Fa = ({
  selectedDate: e,
  onDateChange: t,
  month: n,
  year: r,
  minTime: a,
  maxTime: o,
  startDate: s,
  endDate: i,
  dateType: c
}) => /* @__PURE__ */ xe(Ft, { sx: qt.calenderContainer, children: /* @__PURE__ */ xe(
  Sa,
  {
    mode: "single",
    month: new Date(parseInt(r), parseInt(n)),
    style: { width: "100%" },
    showWeekNumber: !1,
    hideNavigation: !0,
    className: "date-time-selector-calender",
    selected: new Date(e * 1e3),
    onDayClick: (l) => {
      t(J(l, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").unix());
    },
    disabled: {
      before: (() => {
        if (c === "end" && s)
          return J.unix(s).toDate();
        if (a && a > 0)
          return J.unix(a).toDate();
      })(),
      after: (() => c === "start" && i ? J.unix(i).toDate() : o && o !== 1 / 0 ? J.unix(o).toDate() : J().endOf("day").toDate())()
    },
    styles: {
      week: {
        backgroundColor: "transparent"
      }
    }
  }
) });
export {
  Fa as DatePickerComponent
};
