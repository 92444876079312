/**
    Copyright Highway9 Networks Inc.
 */

type Props = {
    size?: number;
};

const Network = ({
    size = 64,
}: Props) => {
    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_7943)">
            <path d="M24.2129 17.9199H37.6529V22.3999H33.1729V40.3199H37.6529V44.7999H28.6929V22.3999H24.2129V17.9199Z" fill="#40B5AF"/>
            <path d="M53.0832 11.4531V28.8731H37.9032V11.4531H53.0832ZM8.7832 11.4531H23.9632V28.8731H8.7832V11.4531ZM53.0832 51.2731H37.9032V33.8531H53.0832V51.2731Z" fill="url(#paint0_linear_1_7943)" stroke="url(#paint1_linear_1_7943)" stroke-width="0.5"/>
            </g>
            <defs>
            <linearGradient id="paint0_linear_1_7943" x1="30.9332" y1="11.2031" x2="30.9332" y2="83.6944" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D9F4F3"/>
            <stop offset="1" stop-color="#D9F4F3" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1_7943" x1="30.9332" y1="11.2031" x2="30.9332" y2="79.0178" gradientUnits="userSpaceOnUse">
            <stop stop-color="#40B5AF"/>
            <stop offset="1" stop-color="#40B5AF" stop-opacity="0"/>
            </linearGradient>
            <clipPath id="clip0_1_7943">
            <rect width="64" height="64" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};
    
export default Network;