import { useContext as n } from "react";
import { a } from "../../../ChatbotContext-74017a6b.js";
const i = (t) => function(o, e, r) {
  return t(o, e, r);
}, c = (t) => async function(o, e, r) {
  return await t(o, r);
}, C = () => {
  const t = n(a);
  if (t === void 0)
    throw new Error("useChatbotContext must be used within a ChatbotProvider");
  return t;
};
export {
  c as useBatchAdapter,
  C as useChatbotContext,
  i as useStreamAdapter
};
