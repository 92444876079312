// @copyright Highway9 Networks

import { RootState, store } from ".";
export function observeStoreChange<T>(select: (state: RootState) => T, onChange: (state: T) => void) {
  let currentState: T;

  function handleChange() {
    const nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}