/**
 * Copyright Highway9 Networks Inc.
 */
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { metricHelper } from "../../helpers/metricHelper";
import { dashboardService } from "../../services";
import { ServiceController } from "../../types/serviceController";
import { MetricsAggQuery } from "~/services/APIServices";
import moment from "moment-timezone";
import { updateURL } from '~/helpers/history';
import { HIGHWAY9 } from "~/constants";

type PeakConnectionsData = {
  name: string;
  color: string;
  data: [number];
};

type timeType = {
  startTime: number;
  endTime: number;
  interval: string;
  diff: number;
}

type initState = {
  serviceControllers: ServiceController[];
  currentController: ServiceController | null;
  metrics: {
    [key: string]: [number, number][];
  };
  peakConnectionsData: PeakConnectionsData[];
  time: timeType;
  mapType: string;
  kpiOptions: {
    plmn: string;
    vendor: string;
  };
};
const time = {
  endTime: moment().unix(),
  startTime: moment().subtract(1, 'day').unix(),
  interval: "1day",
};

const initialState: initState = {
  serviceControllers: [],
  currentController: null,
  metrics: {},
  peakConnectionsData: [],
  time: {
    startTime: time.startTime,
    endTime: time.endTime,
    interval: time.interval,
    diff: time.endTime - time.startTime,


  },
  mapType: "roadmap",
  kpiOptions: {
    plmn: "",
    vendor: HIGHWAY9,
  },
};

export const fetchServiceController = createAsyncThunk("dashboard/fetchServiceController", async () => {
  const controller = await dashboardService.getControllerSummary();
  return controller;
});

async function getMetrics({ startTime, endTime }: { startTime: number; endTime: number }) {
  try {
    console.log("radio_connected....");
    const params: MetricsAggQuery = {
      metrics: [
        "radio_overall_health",
        "radio_overall_health",
        "radio_overall_health",
        "radio_overall_health",
        "radio_overall_health",
        "devices_connected",
      ],
      interval: {
        startTime,
        endTime,
      },
      resolution: metricHelper.getResolution(startTime, endTime),
      queryKeys: [
        {
          key: "radio_overall_health_status",
          value: "UNKNOWN",
        },
        {
          key: "radio_overall_health_status",
          value: "GOOD",
        },
        {
          key: "radio_overall_health_status",
          value: "BAD",
        },
        {
          key: "radio_overall_health_status",
          value: "THIRDPARTY",
        },
        {
          key: "radio_overall_health_status",
          value: "PARTIALLY_GOOD",
        },
      ],
    };
    const data = await dashboardService.getMetrics(params);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const fetchDashboardMetrics = createAsyncThunk("dashboard/fetchDashboardMetrics", getMetrics);
const radioOverallHealthStatusOrder = ["UNKNOWN", "GOOD", "BAD", "THIRDPARTY", "PARTIALLY_GOOD"];
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setPeakConnectionsData: (state, action: PayloadAction<PeakConnectionsData[]>) => {
      state.peakConnectionsData = action.payload;
    },
    setTimeInterval: (state, action: PayloadAction<{
      interval : string; rounding?: number;
    }>) => {
      const { rounding , interval } = action.payload;

      // Check if interval is in the query params
      const intervalParam = new URLSearchParams(window.location.search).get('interval');
      // check if no change in interval or custom interval then return
      if (state.time.interval === interval && intervalParam !== null) return;
      if (interval === "custom") {
        state.time.interval = interval;
        updateURL({
          startTime: state.time.startTime,
          endTime: state.time.endTime,
          interval: state.time.interval,
        });
        return;
      }
        state.time.interval = interval;

      const { startTime, endTime } = metricHelper.getIntervalTime(interval, rounding);
      state.time.startTime = startTime;
      state.time.endTime = endTime;
      state.time.diff = endTime - startTime;

      updateURL({
        startTime: state.time.startTime,
        endTime: state.time.endTime,
        interval: state.time.interval,
      });
    },
    setStartTime: (state, action: PayloadAction<number>) => {
      // check if the data is not same as the current data
      if (state.time.startTime === action.payload) return;
      state.time.startTime = action.payload;
      state.time.diff = state.time.endTime - action.payload;
      updateURL({
        startTime: state.time.startTime,
      });
    },
    setEndTime: (state, action: PayloadAction<number>) => {
      // check if the data is not same as the current data
      if (state.time.endTime === action.payload) return;
      state.time.endTime = action.payload;
      state.time.diff = action.payload - state.time.startTime;
      updateURL({
        endTime: state.time.endTime,
      });
    },
    setMapType: (state, action: PayloadAction<string>) => {
      state.mapType = action.payload;
    },
    setKPIPlmn: (state, action: PayloadAction<string>) => {
      state.kpiOptions.plmn = action.payload;
    },
    setKPIVendor: (state, action: PayloadAction<string>) => {
      state.kpiOptions.vendor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServiceController.fulfilled, (state, action) => {
      state.serviceControllers = action.payload;
      state.currentController = action.payload[0];
    });
    builder.addCase(fetchDashboardMetrics.fulfilled, (state, action) => {
      let x = 0;
      action.payload.forEach((itm: any) => {
        if (itm.metric === "radio_overall_health") {
          const fullMetricName = itm.metric + "_" + radioOverallHealthStatusOrder[x];
          state.metrics[fullMetricName] = itm.metricData || [];
          x++;
        } else {
          state.metrics[itm.metric] = itm.metricData || [];
        }
      });
    });
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
export const selectDashboardKPIOptions = (state: RootState) => state.dashboard.kpiOptions;
export const selectServiceControllers = (state: RootState) => state.dashboard.serviceControllers;
export const selectCurrentServiceController = (state: RootState) => state.dashboard.currentController;
export const DashboardMetrics = (state: RootState) => state.dashboard.metrics;
export const dashboardMapType = (state: RootState) => state.dashboard.mapType;

export const DashboardMetric = (metric: string) => (state: RootState) => state.dashboard.metrics[metric];
export const softwareVersion = (state: RootState) => state.dashboard.currentController?.version;
export const dashboardPeakConnectionsData = (state: RootState) => state.dashboard.peakConnectionsData;
export const selectTimeInterval = (state: RootState) => state.dashboard.time.interval;
export const selectStartTime = (state: RootState) => state.dashboard.time.startTime;
export const selectEndTime = (state: RootState) => state.dashboard.time.endTime;
