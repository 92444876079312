/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */

type Props = {
    size?: number;
};

const DownloadInstall = ({
    size = 20,
}: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
            <mask id="mask0_301_357" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width={size} height={size}>
                <rect width={size} height={size} fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_301_357)">
                <path d="M9.99992 12.9792C9.88881 12.9792 9.78464 12.9618 9.68742 12.9271C9.5902 12.8924 9.49992 12.8334 9.41659 12.75L6.41659 9.75004C6.24992 9.58337 6.17006 9.38893 6.177 9.16671C6.18395 8.94449 6.26381 8.75004 6.41659 8.58337C6.58325 8.41671 6.78117 8.3299 7.01034 8.32296C7.2395 8.31601 7.43742 8.39587 7.60408 8.56254L9.16658 10.125V4.16671C9.16658 3.9306 9.24645 3.73268 9.40617 3.57296C9.56589 3.41324 9.76381 3.33337 9.99992 3.33337C10.236 3.33337 10.4339 3.41324 10.5937 3.57296C10.7534 3.73268 10.8333 3.9306 10.8333 4.16671V10.125L12.3958 8.56254C12.5624 8.39587 12.7603 8.31601 12.9895 8.32296C13.2187 8.3299 13.4166 8.41671 13.5833 8.58337C13.736 8.75004 13.8159 8.94449 13.8228 9.16671C13.8298 9.38893 13.7499 9.58337 13.5833 9.75004L10.5833 12.75C10.4999 12.8334 10.4096 12.8924 10.3124 12.9271C10.2152 12.9618 10.111 12.9792 9.99992 12.9792ZM4.99992 16.6667C4.54159 16.6667 4.14922 16.5035 3.82284 16.1771C3.49645 15.8507 3.33325 15.4584 3.33325 15V13.3334C3.33325 13.0973 3.41311 12.8993 3.57284 12.7396C3.73256 12.5799 3.93047 12.5 4.16659 12.5C4.4027 12.5 4.60061 12.5799 4.76034 12.7396C4.92006 12.8993 4.99992 13.0973 4.99992 13.3334V15H14.9999V13.3334C14.9999 13.0973 15.0798 12.8993 15.2395 12.7396C15.3992 12.5799 15.5971 12.5 15.8333 12.5C16.0694 12.5 16.2673 12.5799 16.427 12.7396C16.5867 12.8993 16.6666 13.0973 16.6666 13.3334V15C16.6666 15.4584 16.5034 15.8507 16.177 16.1771C15.8506 16.5035 15.4583 16.6667 14.9999 16.6667H4.99992Z" fill="#226A58" />
            </g>
        </svg>
    );
};

export default DownloadInstall;