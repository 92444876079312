import { jsx as o } from "react/jsx-runtime";
import "../../Chatbot.style-84206d19.js";
import "@mui/material";
import "react";
import "../atoms/FormControl/FormControl.js";
import "../atoms/FormControlLabel/FormControlLabel.js";
import "../../map-85279df3.js";
import "../../server.browser-ec1ee1ca.js";
import "../atoms/Grid/Grid.js";
import "../atoms/Paper/Paper.js";
import "../atoms/Box/Box.js";
import "../atoms/Radio/Radio.js";
import "../atoms/RadioGroup/RadioGroup.js";
import "../atoms/Typography/Typography.js";
import "../atoms/Stepper/Stepper.js";
import "../atoms/Step/Step.js";
import "../atoms/StepLabel/StepLabel.js";
import "../../style.module-3bbdaa05.js";
import "../../DateTimeSelectorMenu-62cb07f8.js";
import "moment-timezone";
import "../../runtime-6393d96e.js";
import "../../TableContext-c5440376.js";
import "../ReactTable/SearchFilter.js";
import "../ReactTable/TablePaginationActions.js";
import "../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../transform-17824b7f.js";
import "../molecules/NetworkMap/NetworkMapUtils.js";
import c from "../../assets/icons/UserIcon.js";
import a from "../../assets/icons/AgentIcon.js";
import "../../ChatbotContext-74017a6b.js";
function S({ name: n, role: r, avatar: i, color: m, size: p = 24 }) {
  const s = r === "assistant", e = r === "user", t = `${n}`;
  return /* @__PURE__ */ o("span", { className: "avatar", title: t, id: r, children: (() => {
    if (i)
      return i;
    if (s)
      return /* @__PURE__ */ o(a, { title: t, size: p, color: m ?? "#616161" });
    if (e)
      return /* @__PURE__ */ o(c, { title: t, size: p, color: m ?? "#616161" });
  })() });
}
export {
  S as Avatar
};
