import { jsxs as s, jsx as e } from "react/jsx-runtime";
import { Avatar as a } from "./Avatars.js";
import { useChatbotContext as n } from "./hooks/index.js";
function h({ persona: i, starters: t = [] }) {
  return /* @__PURE__ */ s("div", { className: "chatbot-welcome", children: [
    /* @__PURE__ */ s("div", { className: "welcome", children: [
      /* @__PURE__ */ e(a, { ...i, size: 42 }),
      /* @__PURE__ */ e("div", { className: "title", children: i.name }),
      /* @__PURE__ */ e("div", { className: "subtitle", children: i.tagline })
    ] }),
    /* @__PURE__ */ e(o, { actions: t })
  ] });
}
function o({ actions: i }) {
  const { send: t } = n();
  return /* @__PURE__ */ e("div", { className: "quick-actions", children: i.map((c, l) => /* @__PURE__ */ e("div", { className: "action", onClick: () => {
    t(c.prompt);
  }, children: c.title }, l)) });
}
export {
  h as Welcome
};
