/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "..";
import { Types } from "../../constants/types";
import { radioCoreKPIsListService, radioMetricsTemplateService, radioService } from "../../services";
import { alarmService } from "../../services/alarm-service";
import { Alarm, AlarmQuery } from "../../types/alarm";
import { RadioGroup } from "~/types/radioGroup";
import { ISite } from "~/types/site";
import Radio, { RadioAvailableUpgrads, RadioX } from "../../types/radio";
import { RadioFilter } from "../../types/radioFilter";
import {
  getDeviceRunningData,
  getEdgeGaugeData,
  getRFGaugeData,
  getVMCGaugeData,
} from "../../views/radios/Graphs/graphHelper";
import { updateRadioDetails } from "../../views/radios/radioHelper";
import { fillTimeSeriesData } from "../../views/subscribers/graphs/graphHelper";
import { BAICELLS, HIGHWAY9 } from "~/constants";
import { observeStoreChange } from "../utils";
import { selectCompareEnable } from "./utilitySlice";
import { CoreKPIsList } from "~/types/metricGraph";
import { MetricsQuery } from "~/services/APIServices";


type initState = {
  open: boolean;
  edit: boolean;
  installInfoOpen: boolean;
  rebootOpen: boolean;
  forceConfigOpen: boolean;
  installInfoDhcpTypeSelection: string;
  installInfoEdgeIp: string;
  radioupgradeOpen: boolean;
  loading: boolean;
  current: Radio | null;
  data: Array<RadioX | Radio>;
  deleteOpen: boolean;
  count: number;
  availableUpgrades: RadioAvailableUpgrads[];
  alarm: {
    loading: boolean;
    data: Array<Alarm>;
    count: number;
    /** @enum state ALL , ACTIVE */
    state: string;
    /** @enum carrier cell1 , cell2 */
    carrier: string;
  };
  import: {
    open: boolean;
    popupOpen: boolean;
    id: string | null;
    isRunning: boolean;
    data: any[];
    filteredData: any[];
    IRadioGroupData: RadioGroup[];
    IRadioSiteData: ISite[];
    dataCount: number;
    filteredDataCount: number;
    preserveSign: boolean;
  };
  export: {
    open: boolean;
    id: string | null;
    isRunning: boolean;
  };
  edgeGauge: {
    data: {
      connected: number;
      disconnected: number;
      unknown: number;
      partiallyConnected: number;
      thirdParty: number;
    };
  };
  rfGauge: {
    data: {
      good: number;
      bad: number;
      off: number;
      needAttention: number;
      unknown: number;
      thirdParty: number;
    };
  };

  vmcGauge: {
    data: {
      active: number;
      inactive: number;
      unknown: number;
      thirdParty: number;
    };
  };

  deviceRunning: {
    data: {
      1: number;
      2: number;
      3: number;
      4: number;
    };
  };

  countStatus: {
    green: number;
    orange: number;
    red: number;
    warning: number;
    unconfigured: number;
    thirdParty: number;
    ungrouped: number;
    total: number;
  };

  filter: {
    red: boolean;
    orange: boolean;
    green: boolean;
    warnings: boolean;
    unconfigured: boolean;
    thirdParty: boolean;
    ungrouped: boolean;
  };

  kpiExport: {
    open: boolean;
    operationID: string | null;
    isRunning: boolean;
  };

  kpiOptions: {
    cellId: number | string;
    plmn: string;
    vendor: string;
    level: string;
    technology: string;
  };

  metricsLoading: boolean;
  metricsError: string | null;
  metrics: {
    [key: string]: [number, number][];
  };

  compareMetrics: {
    [key: string]: [number, number][];
  };

  aggregatedMetrics: {
    [key: string]: [number, number][];
  };

  /** RadioID to RadioNames */
  radioMap: Record<string, string>; // radioId: radioName

  radioCoreKPIList: CoreKPIsList[];

  exportKPIId: string | null;
  loadingExportKPI: boolean;
};

const initialState: initState = {
  open: false,
  edit: false,
  installInfoOpen: false,
  rebootOpen: false,
  forceConfigOpen: false,
  installInfoDhcpTypeSelection: "isc-dhcp-server",
  installInfoEdgeIp: "",

  loading: true,
  current: null,
  data: [],
  deleteOpen: false,
  count: 0,
  availableUpgrades: [],
  alarm: {
    loading: true,
    data: [],
    count: 0,
    state: "ACTIVE",
    carrier: "cell1",
  },

  import: {
    open: false,
    popupOpen: false,
    id: null,
    isRunning: false,
    data: [],
    filteredData: [],
    IRadioGroupData: [],
    IRadioSiteData: [],
    dataCount: 0,
    filteredDataCount: 0,
    preserveSign: true,
  },
  export: {
    open: false,
    id: null,
    isRunning: false,
  },
  edgeGauge: {
    data: {
      connected: 0,
      disconnected: 0,
      unknown: 0,
      partiallyConnected: 0,
      thirdParty: 0,
    },
  },
  rfGauge: {
    data: {
      good: 0,
      bad: 0,
      off: 0,
      unknown: 0,
      thirdParty: 0,
      needAttention: 0,
    },
  },
  vmcGauge: {
    data: {
      active: 0,
      inactive: 0,
      unknown: 0,
      thirdParty: 0,
    },
  },
  deviceRunning: {
    data: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    },
  },
  countStatus: {
    green: 0,
    orange: 0,
    red: 0,
    warning: 0,
    unconfigured: 0,
    thirdParty: 0,
    ungrouped: 0,
    total: 0,
  },

  filter: {
    red: true,
    orange: true,
    green: true,
    warnings: true,
    unconfigured: true,
    thirdParty: true,
    ungrouped: true,
  },

  kpiExport: {
    open: false,
    operationID: null,
    isRunning: false,
  },
  kpiOptions: {
    cellId: "1|2",
    plmn: "",
    vendor: HIGHWAY9,
    technology: "4G",
    level: '',
  },
  metricsLoading: true,
  metricsError: null,
  metrics: {},
  compareMetrics: {},
  aggregatedMetrics: {},
  radioMap: {},
  radioupgradeOpen: false,

  radioCoreKPIList: [],
  exportKPIId: null,
  loadingExportKPI: false,
};

export const fetchRadioMetrics = createAsyncThunk(`radio/fetchRadioMetrics`, async (query: MetricsQuery, thunkApi) => {
  try {
    const data = await radioService.getMetrics(query);
    return data;
  } catch (err: any) {
    console.log("Error in fetchRadioMetrics", err);
    const errorMsg = err.errors[0].message;
    return thunkApi.rejectWithValue(errorMsg)
  }
});
export const fetchRadioMetricsCompare = createAsyncThunk(`radio/fetchRadioMetricsCompare`, radioService.getMetrics);
export const fetchRadioMetricsTemplate = createAsyncThunk(`radio/fetchRadioMetricsTemplate`, radioMetricsTemplateService.getTemplates);
export const fetchRadioMetricsAggregate = createAsyncThunk(
  `radio/fetchRadioMetricsAggregate`,
  radioService.getMetricsAggregate
);
export const fetchRadioCoreKPIList = createAsyncThunk(`radio/fetchRadioCoreKPIList`, radioCoreKPIsListService.getRadioCoreKPIsList);

export const fetchRadios = createAsyncThunk(`radio/fetchRadios`, async ( query?: {timestamp?: number} ) => {
  const timestamp = query?.timestamp;
  try {
    const query = timestamp ? { timestamp } : {};
    const data = await radioService.getRadios(query);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const fetchRadioAlarms = createAsyncThunk(`radio/fetchRadioAlarms`, async (query: AlarmQuery) => {
  try {
    const alarms = await alarmService.getAlarms(query);
    return alarms;
  } catch (error) {
    console.error(error);
    return [];
  }
});

export const copyRadioName = createAsyncThunk(`radio/copyName`, (_: void, { getState }) => {
  const state = getState() as RootState;
  const radio = state.radio.current;
  if (radio) {
    const name = radio.name;
    navigator.clipboard.writeText(name);
    return name;
  }
});

export const copyRadioSerial = createAsyncThunk(`radio/copySerial`, (_: void, { getState }) => {
  const state = getState() as RootState;
  const radio = state.radio.current;
  if (radio) {
    const serial = radio.serialNumber;
    navigator.clipboard.writeText(serial ?? "");
    return serial;
  }
});

const radioSlice = createSlice({
  name: Types.radio,
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setEdit: (state, action: PayloadAction<boolean>) => {
      state.edit = action.payload;
    },
    setValues: (state, action: PayloadAction<Radio | null>) => {
      state.current = action.payload;
    },
    setData: (state, action: PayloadAction<Radio[]>) => {
      state.data = action.payload;
    },
    updateData: (state, action: PayloadAction<Radio>) => {
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      state.data[index] = action.payload;
    },
    addData: (state, action: PayloadAction<Radio>) => {
      state.data.push(action.payload);
    },
    delete: (state, action: PayloadAction<string>) => {
      const index = state.data.findIndex((item) => item.id === action.payload);
      state.data = state.data.filter((_, i) => i !== index);
    },
    setDeleteOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteOpen = action.payload;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setInstallInfoOpen: (state, action: PayloadAction<boolean>) => {
      state.installInfoOpen = action.payload;
    },
    setInstallInfoDhcpTypeSelection: (state, action: PayloadAction<string>) => {
      state.installInfoDhcpTypeSelection = action.payload;
    },
    setInstallInfoEdgeIp: (state, action: PayloadAction<string>) => {
      state.installInfoEdgeIp = action.payload;
    },
    setRebootOpen: (state, action: PayloadAction<boolean>) => {
      state.rebootOpen = action.payload;
    },
    setForceConfigOpen: (state, action: PayloadAction<boolean>) => {
      state.forceConfigOpen = action.payload;
    },
    setUpgradeRadio: (state, action: PayloadAction<boolean>) => {
      state.radioupgradeOpen = action.payload;
    },
    setOpenImport: (state, action: PayloadAction<boolean>) => {
      state.import.open = action.payload;
    },
    setImportData: (state, action: PayloadAction<any[]>) => {
      state.import.data = action.payload;
      state.import.dataCount = action.payload.length;
      state.import.filteredData = action.payload.filter(
        (item: any) => item["Action (ADD/UPDATE/DELETE)"] === "UPDATE" || item["Action (ADD/UPDATE/DELETE)"] === "DELETE" || item["Action (ADD/UPDATE/DELETE)"] === "ADD"
      );
      state.import.filteredDataCount = state.import.filteredData.length;
    },
    setImportPopupOpen: (state, action: PayloadAction<boolean>) => {
      state.import.open = action.payload;
    },
    setImportId: (state, action: PayloadAction<string | null>) => {
      state.import.id = action.payload;
    },
    setImportRadioRunning: (state, action: PayloadAction<boolean>) => {
      state.import.isRunning = action.payload;
    },
    updateIRadioSiteUpdate: (state, action: PayloadAction<ISite[]>) => {
      state.import.IRadioSiteData = action.payload;
    },
    updateIRadioGroupUpdate: (state, action: PayloadAction<{ data: RadioGroup; index: number }>) => {
      const { data, index } = action.payload;
      state.import.IRadioGroupData[index] = data;
    },

    setPreserveSign: (state, action: PayloadAction<boolean>) => {
      state.import.preserveSign = action.payload;
    },
    setFilter: (state, action: PayloadAction<{ type: RadioFilter; value: boolean }>) => {
      state.filter[action.payload.type] = action.payload.value;
    },
    toggleFilter: (state, action: PayloadAction<RadioFilter>) => {
      state.filter[action.payload] = !state.filter[action.payload];
    },
    selectFilter: (state, action: PayloadAction<RadioFilter>) => {
      state.filter[action.payload] = true;
      // deselect all other filters
      Object.keys(state.filter).forEach((key) => {
        if (key !== action.payload) {
          state.filter[key as RadioFilter] = false;
        }
      });
    },
    clearFilter: (state) => {
      Object.keys(state.filter).forEach((key) => {
        state.filter[key as RadioFilter] = true;
      });
    },
    setExportOpen: (state, action: PayloadAction<boolean>) => {
      state.export.open = action.payload;
    },
    setExportId: (state, action: PayloadAction<string | null>) => {
      state.export.id = action.payload;
    },
    setExportIsRunning: (state, action: PayloadAction<boolean>) => {
      state.export.isRunning = action.payload;
    },

    setAlarmCarrier: (state, action: PayloadAction<string>) => {
      state.alarm.carrier = action.payload;
    },
    setAlarmState: (state, action: PayloadAction<string>) => {
      state.alarm.state = action.payload;
    },
    setKPIExportOpen(state, action: PayloadAction<boolean>) {
      state.kpiExport.open = action.payload;
    },
    setKPIExportID(state, action: PayloadAction<string | null>) {
      state.kpiExport.operationID = action.payload;
    },
    setKPIExportRunning(state, action: PayloadAction<boolean>) {
      state.kpiExport.isRunning = action.payload;
    },

    // kpi options
    setKPICellId(state, action: PayloadAction<number | string>) {
      state.kpiOptions.cellId = action.payload;
    },
    setKPIPlmn(state, action: PayloadAction<string>) {
      state.kpiOptions.plmn = action.payload;
    },
    setKPILevel(state, action: PayloadAction<string>) {
      state.kpiOptions.level = action.payload;
    },
    setKPITechnology(state, action: PayloadAction<string>) {
      state.kpiOptions.technology = action.payload;
    },
    setKPIVendor(state, action: PayloadAction<string>) {
      if (action.payload === BAICELLS) {
        state.kpiOptions.vendor = HIGHWAY9;
      } else {
        state.kpiOptions.vendor = action.payload;
      }
    },


    clearCompareMetrics(state) {
      state.compareMetrics = {}
    },
    setExportKPIId(state, action: PayloadAction<string | null>) {
      state.exportKPIId = action.payload
    },
    setLoadingExportKPI(state, action: PayloadAction<boolean>) {
      state.loadingExportKPI = action.payload
    },
  },


  extraReducers: (builder) => {
    builder
      .addCase(fetchRadios.fulfilled, (state, action) => {
        // update radio data
        const { data, count } = updateRadioDetails(action.payload as RadioX[], state.filter);
        state.data = data;
        state.count = data.length;
        state.countStatus = count;
        // map ids to names
        state.radioMap = data.reduce((map, item) => {
          map[item.id!] = item.name;
          return map;
        }, {} as Record<string, string>);

        // update graphs data
        updateGraphs(state as any, data);

        // sync the current radio state with the updated data
        if (state.current?.id && !state.open) {
          const currRadio = state.data.find((item) => item.id === state.current?.id);
          if (currRadio) {
            state.current = currRadio;
          }
        }
        state.loading = false;
      })
      .addCase(fetchRadios.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.count = 0;
        // If we throw error, then the state reset is not executed
        console.error(action.error);
      })

      .addCase(fetchRadioAlarms.fulfilled, (state, action) => {
        state.alarm.data = action.payload;
        state.alarm.count = action.payload.length;
        state.alarm.loading = false;
      })

      .addCase(fetchRadioAlarms.rejected, (state, action) => {
        state.alarm.loading = false;
        state.alarm.data = [];
        console.error(action.error);
        throw action.error;
      })

      .addCase(fetchRadioMetrics.pending, (state, action) => {
        // console.log("fetchRadioMetrics.pending", action);
        if (action.meta.arg.showLoading) state.metricsLoading = true;
      })

      .addCase(fetchRadioMetrics.fulfilled, (state, action) => {
        const data = action.payload;
        data.forEach((obj) => {
          const metricData = obj.metricData?.map((metric) => {
            if (!metric.dataPoints.length) return [];
            try {
              return fillTimeSeriesData({
                data: metric.dataPoints,
                startTime: action.meta.arg.interval.startTime,
                endTime: action.meta.arg.interval.endTime,
                interval: action.meta.arg.resolution,
                fillType: "null",
                name: obj.metric,
              });
            } catch (e) {
              console.error("Error in fillTimeSeries", e, obj.metric, metric.dataPoints);
              return metric.dataPoints.length ? metric.dataPoints : [];
            }
          });
          if (action.meta.arg.aggregate) {
            state.aggregatedMetrics[obj.metric] = metricData[0];
          } else {
            state.metrics[obj.metric] = metricData?.[0] ?? [];
          }
        });
        state.metricsError = null;
        state.metricsLoading = false;
      })

      .addCase(fetchRadioMetricsCompare.fulfilled, (state, action) => {
        const data = action.payload;
        data.forEach((obj) => {
          const metricData = obj.metricData.map((metric) => {
            if (!metric.dataPoints.length) return [];
            try {
              return fillTimeSeriesData({
                data: metric.dataPoints,
                startTime: action.meta.arg.interval.startTime,
                endTime: action.meta.arg.interval.endTime,
                interval: action.meta.arg.resolution,
                fillType: "null",
                name: obj.metric,
              });
            } catch (e) {
              console.error("Error in fillTimeSeries", e, obj.metric, metric.dataPoints);
              return metric.dataPoints.length ? metric.dataPoints : [];
            }
          });
          state.compareMetrics[obj.metric] = metricData[0];
        });
        state.metricsLoading = false;
      })

      .addCase(fetchRadioMetrics.rejected, (state, action) => {
        state.metricsLoading = false;
        state.metricsError = action.payload as string
      })

      .addCase(fetchRadioMetricsTemplate.rejected, (state, action) => {
        console.log(action.error)
        throw action.error
      })
      .addCase(fetchRadioCoreKPIList.fulfilled, (state, action) => {
        state.radioCoreKPIList = action.payload as unknown as CoreKPIsList[]
      })
      .addCase(fetchRadioCoreKPIList.rejected, (state, action) => {
        console.log(action.error)
        throw action.error
      })
      .addCase(fetchRadioMetricsAggregate.fulfilled, (state, action) => {
        const data = action.payload;
        data.forEach((obj) => {
          if (!obj.metricData.length) return [];
          try {
            const metricData = fillTimeSeriesData({
              data: obj.metricData,
              startTime: action.meta.arg.interval.startTime,
              endTime: action.meta.arg.interval.endTime,
              interval: action.meta.arg.resolution,
              fillType: "previous",
            });
            state.aggregatedMetrics[obj.metric] = metricData;
          } catch (e) {
            console.error("Error in fillTimeSeries", e, obj.metric, obj.metricData);
            const metricData = obj.metricData.length ? obj.metricData : [];
            state.aggregatedMetrics[obj.metric] = metricData;
          }
        });
      })

      .addCase(fetchRadioMetricsAggregate.rejected, (state, action) => {
        console.error(action.error);
        throw action.error;
      });
  },
});

export const radioActions = radioSlice.actions;
export default radioSlice.reducer;
export const radioState = (state: RootState) => state.radio.current;
export const radioData = (state: RootState) => state.radio.data;
export const radioOpen = (state: RootState) => state.radio.open;
export const radioEdit = (state: RootState) => state.radio.edit;
export const radioUpgradeOpen = (state: RootState) => state.radio.radioupgradeOpen;
export const radioDeleteOpen = (state: RootState) => state.radio.deleteOpen;
export const radioCount = (state: RootState) => state.radio.count;
export const radioLoading = (state: RootState) => state.radio.loading;

export const radioInstallInfoOpen = (state: RootState) => state.radio.installInfoOpen;
export const radioRebootOpen = (state: RootState) => state.radio.rebootOpen;
export const radioForceConfigureOpen = (state: RootState) => state.radio.forceConfigOpen;
export const radioInstallInfoDhcpTypeSelection = (state: RootState) => state.radio.installInfoDhcpTypeSelection;
export const radioInstallInfoEdgeIp = (state: RootState) => state.radio.installInfoEdgeIp;

export const radioImportOpen = (state: RootState) => state.radio.import.open;
export const radioImportData = (state: RootState) => state.radio.import.data;
export const radioImportDataCount = (state: RootState) => state.radio.import.dataCount;
export const radioPreserveSign = (state: RootState) => state.radio.import.preserveSign;
export const radioImportFilteredData = (state: RootState) => state.radio.import.filteredData;
export const radioImportFilteredDataCount = (state: RootState) => state.radio.import.filteredDataCount;
export const ImportRadioGroupData = (state: RootState) => state.radio.import.IRadioGroupData;
export const ImportRadioSiteData = (state: RootState) => state.radio.import.IRadioSiteData;

export const radioImportPopupOpen = (state: RootState) => state.radio.import.popupOpen;
export const radioImportId = (state: RootState) => state.radio.import.id;
export const radioImportRunning = (state: RootState) => state.radio.import.isRunning;

export const radioEdgeGaugeData = (state: RootState) => state.radio.edgeGauge.data;
export const radioRFGaugeData = (state: RootState) => state.radio.rfGauge.data;
export const radioVMCGaugeData = (state: RootState) => state.radio.vmcGauge.data;
export const radioDeviceRunningData = (state: RootState) => state.radio.deviceRunning.data;

export const radioCountStatus = (state: RootState) => state.radio.countStatus;
export const radioFilter = (state: RootState) => state.radio.filter;

export const radioExportOpen = (state: RootState) => state.radio.export.open;
export const radioExportId = (state: RootState) => state.radio.export.id;
export const radioExportIsRunning = (state: RootState) => state.radio.export.isRunning;

export const radioAlarm = (state: RootState) => state.radio.alarm;

export const radioMetrics = (state: RootState) => state.radio.metrics;
export const radioMetricsError = (state: RootState) => state.radio.metricsError;
export const radioCompareMetrics = (state: RootState) => state.radio.compareMetrics;
export const radioAggregatedMetrics = (state: RootState) => state.radio.aggregatedMetrics;
export const radioMetricsLoading = (state: RootState) => state.radio.metricsLoading;

export const radioMetric = (metric: string) => (state: RootState) => state.radio.metrics[metric];
export const radioAggregatedMetric = (metric: string) => (state: RootState) => state.radio.aggregatedMetrics[metric];

export const radioMap = (state: RootState) => state.radio.radioMap;
export const radioKPIOption = (state: RootState) => state.radio.kpiOptions;

export const exportKPIId = (state: RootState) => state.radio.exportKPIId;
export const loadingExportKPI = (state: RootState) => state.radio.loadingExportKPI;

export const getRadioCoreKPIList = (state: RootState) => state.radio.radioCoreKPIList


function updateGraphs(state: initState, data: Radio[]) {
  // update edge gauge data
  const edgeGaugeData = getEdgeGaugeData(data);
  state.edgeGauge.data = edgeGaugeData;

  // update rf gauge data
  const rfGaugeData = getRFGaugeData(data);
  state.rfGauge.data = rfGaugeData;

  // update vmc gauge data
  const vmcGaugeData = getVMCGaugeData(data);
  state.vmcGauge.data = vmcGaugeData;

  const deviceRunningData = getDeviceRunningData(data);
  state.deviceRunning.data = deviceRunningData;
}


// clear compare metrics when compare is disabled
setTimeout(() => {
  observeStoreChange(selectCompareEnable, (state) => {
    if (!state) {
      store.dispatch(radioActions.clearCompareMetrics())
    }
  })
})