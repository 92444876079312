import { jsx as e } from "react/jsx-runtime";
import { FormControlLabel as l } from "@mui/material";
import { forwardRef as a } from "react";
const i = a(({ sx: o, ...r }, t) => /* @__PURE__ */ e(l, { ref: t, sx: [m.defaultStyles, ...Array.isArray(o) ? o : [o]], ...r })), m = {
  defaultStyles: {
    ml: 0,
    gap: "8px",
    userSelect: "none",
    ".MuiFormControlLabel-label": {
      fontSize: "12px",
      fontWeight: 400
    }
  }
};
export {
  i as FormControlLabel
};
