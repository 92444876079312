import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Policy } from "~/types/policy";

type initState = {
    current: Policy,
    isEdit: boolean;
}

const initialState: initState = {
    current: {
        name: "",
        flowRules: [
            {
                name: "",
                QosProfileType: "default",
                flows: [
                    {
                        destinationIPRanges: [{ip: ""}],
                        destinationPorts: [{port: 0}],
                        protocols: [{protocol: ""}],
                    }
                ]
            }
        ],
        runtimeInfo: null,
    },
    isEdit: false,
}

export const policiesSlice = createSlice({
    name: "policies",
    initialState,
    reducers: {
        setCurrent: (state, action: PayloadAction<Policy>) => {
            state.current = action.payload;
        },
        setIsEdit: (state, action: PayloadAction<boolean>) => {
            state.isEdit = action.payload;
        },
        resetCurrentPolicy: (state) => {
            state.current = initialState.current;
        }
    }
});

export const policiesActions = policiesSlice.actions;

export default policiesSlice.reducer;

export const selectCurrentPolicy = (state: RootState) => state.policies.current;