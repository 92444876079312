/**
 * Copyright Highway9 Networks Inc.
 */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { operatorService } from "../../services/operator-service";
import { OperatorQuery } from "../../types/OperatorQuery";

type initState = {
  open: boolean;
  current: OperatorQuery | null;
  allOperators: OperatorQuery[];
  data: OperatorQuery[];
  error?: string | null;
  timers: { [key: string]: number };
  action: { [key: string]: string };
  export: {
    open: boolean;
    id: string | null;
    isRunning: boolean;
  };
  importJsonData: Partial<OperatorQuery>;
  isImportRunning:boolean;
};

export const initialState: initState = {
  open: false,
  current: null,
  allOperators: [],
  data: [],
  timers: {},
  action: {},
  export: {
    open: false,
    id: null,
    isRunning: false,
  },
  importJsonData: {},
  isImportRunning:false,

};

export const fetchAllOperators = createAsyncThunk("operator/fetchAllOperators", async () => {
  try {
    const response = await operatorService.getAllOperators();
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchOperatorsByEdge = createAsyncThunk(
  "operator/fetchOperatorsByEdge",
  async (edgeId: string | string[]) => {
    try {
      // check if edgeId is an array
      if (Array.isArray(edgeId)) {
        // Call Promise.all to get all the operators
        const operators = await Promise.all(
          edgeId.map(async (id) => {
            const response = await operatorService.getOperatorsByEdge(id);
            return response;
          })
        );
        // flatten the array of arrays
        const flattened = operators.flat();
        return flattened;
      } else {
        const response = await operatorService.getOperatorsByEdge(edgeId);
        return response;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const operatorSlice = createSlice({
  name: "operator",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setValues: (state, action: PayloadAction<OperatorQuery>) => {
      state.current = action.payload;
    },
    setData: (state, action: PayloadAction<OperatorQuery[]>) => {
      state.data = action.payload;
    },
    setTimer: (state, action: PayloadAction<{ operatorId: string; time: number }>) => {
      state.timers[action.payload.operatorId] = action.payload.time;
    },
    decrementTimer: (state, action: PayloadAction<string>) => {
      if (state.timers[action.payload] > 0) {
        state.timers[action.payload] -= 1;
      }
    },
    setAction: (state, action: PayloadAction<{ operatorId: string; actionType: string }>) => {
      state.action[action.payload.operatorId] = action.payload.actionType;
    },
    setExportOpen: (state, action: PayloadAction<boolean>) => {
      state.export.open = action.payload;
    },
    setExportId: (state, action: PayloadAction<string | null>) => {
      state.export.id = action.payload;
    },
    setExportIsRunning: (state, action: PayloadAction<boolean>) => {
      state.export.isRunning = action.payload;

    },
    setImportJsonData: (state, action: PayloadAction<OperatorQuery>) => {
      state.importJsonData = action.payload;

    },
    setIsImportRunning: (state, action: PayloadAction<boolean>) => {
      state.isImportRunning = action.payload;

    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOperators.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchAllOperators.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchOperatorsByEdge.fulfilled, (state, action) => {
        state.allOperators = action.payload;
      })
      .addCase(fetchOperatorsByEdge.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const operatorActions = operatorSlice.actions;
export default operatorSlice.reducer;

export const operatorOpen = (state: RootState) => state.operator.open;
export const operatorState = (state: RootState) => state.operator.current;
export const operatorEdgeAll = (state: RootState) => state.operator.allOperators;
export const operatorData = (state: RootState) => state.operator.data;
export const Timer = (state: RootState) => state.operator.timers;
export const operatorAction = (state: RootState) => state.operator.action;
export const operatorExportOpen = (state: RootState) => state.operator.export.open;
export const operatorExportId = (state: RootState) => state.operator.export.id;
export const operatorExportIsRunning = (state: RootState) => state.operator.export.isRunning;
export const operatorImportJSONData = (state: RootState) => state.operator.importJsonData;
export const operatorIsImportRunning = (state: RootState) => state.operator.isImportRunning;
