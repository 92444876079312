/**
 * Copyright Highway9 Networks Inc.
 */
import * as React from "react";
import Button from "@mui/material/Button";

import DialogContentText from "@mui/material/DialogContentText";

import { LoadingButton } from "@mui/lab";
import { DialogBox, DialogActions, DialogContent, DialogTitle } from "~/styles/Dialog";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string | JSX.Element;
  message: string | JSX.Element;
  onCancel?: () => void;
  onConfirm?: () => void | Promise<void>;
  confirmText?: string;
  cancelText?: string;
  confirmColor?: "error" | "success" | "warning" | "info" | "primary" | "secondary";
  cancelButton?: boolean;
  confirmButton?: boolean;
  children?: JSX.Element;
  backdropClick?: boolean;
  disable?: boolean;
  errors?: any[];
};

export default function AlertDialog(props: Props) {
  const {
    open,
    setOpen,
    title,
    message,
    onCancel,
    onConfirm,
    confirmText = "OK",
    cancelText = "Cancel",
    cancelButton = true,
    confirmButton = true,
    confirmColor = "primary",
    children,
    disable = false,
    errors
  } = props;
  const [loading, setLoading] = React.useState(false);


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DialogBox
        data-testid="alert-dialog-container"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          {cancelButton && (
            <Button
              data-testid="alert-dialog-cancel-btn"
              onClick={() => {
                try {
                  if (onCancel) onCancel();
                  handleClose();
                } catch (error) {
                  console.log(error);
                  throw error;
                }
              }}
            >
              {cancelText}
            </Button>
          )}
          {confirmButton && (
            <LoadingButton
              data-testid="alert-dialog-confirm-btn"
              variant="contained"
              color={confirmColor}
              disabled={!errors?.length ? disable : true}
              loading={loading}
              onClick={async () => {
                try {
                  setLoading(true);
                  if (onConfirm) await onConfirm();
                  // handleClose();
                } catch (error) {
                  setLoading(false);
                  console.log("Alert : ", error);
                }
              }}
              autoFocus
            >
              {confirmText}
            </LoadingButton>
          )}
        </DialogActions>
      </DialogBox>
    </div>
  );
}
