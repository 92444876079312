/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */

type Props = {
    size?: number;
};

const MobilityEdge = ({
    size = 64,
}: Props) => {
    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1_7937" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="65" height="64">
            <rect x="0.5" width="64" height="64" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_1_7937)">
            <path d="M27.167 20.4045C28.1754 21.6267 29.449 22.4616 30.9876 22.9092H34.013C35.5516 22.4616 36.8252 21.6267 37.8336 20.4045C38.8421 19.1823 39.3463 17.7949 39.3463 16.2425C39.3463 14.3394 38.6823 12.7227 37.3543 11.3925C36.0259 10.0618 34.4094 9.39648 32.505 9.39648C30.6005 9.39648 28.9827 10.0618 27.6516 11.3925C26.3201 12.7227 25.6543 14.3394 25.6543 16.2425C25.6543 17.7949 26.1585 19.1823 27.167 20.4045Z" fill="#40B5AF"/>
            <path d="M44.6913 54.1258C44.3246 53.7574 44.1413 53.3005 44.1413 52.7552V47.1326C44.1413 46.8483 44.0284 46.5756 43.8273 46.3745L33.2588 35.806C32.8401 35.3873 32.1612 35.3873 31.7425 35.806L21.174 46.3745C20.9729 46.5756 20.86 46.8483 20.86 47.1326V52.7552C20.86 53.3005 20.6767 53.7574 20.31 54.1258C19.9438 54.4943 19.4893 54.6785 18.9467 54.6785H11.7467C11.204 54.6785 10.7495 54.4952 10.3833 54.1285L44.6913 54.1258ZM44.6913 54.1258C45.0575 54.4943 45.5118 54.6785 46.054 54.6785M44.6913 54.1258L46.054 54.6785M46.054 54.6785H53.254M46.054 54.6785H53.254M53.254 54.6785C53.7966 54.6785 54.2511 54.4954 54.6173 54.1292L53.254 54.6785ZM10.5433 53.9687L10.5432 53.9686C10.2216 53.647 10.0601 53.2511 10.0601 52.7652V45.5652C10.0601 45.0802 10.2224 44.6846 10.5461 44.3629L10.5462 44.3628C10.87 44.0406 11.2685 43.8787 11.7573 43.8787H18.9386C19.2829 43.8787 19.6131 43.7419 19.8566 43.4984L30.8339 32.5218C31.0773 32.2783 31.2141 31.9481 31.2141 31.6037V23.1353H33.7872V31.6037C33.7872 31.9481 33.924 32.2783 34.1674 32.5218L45.1447 43.4984C45.3882 43.7419 45.7184 43.8787 46.0627 43.8787H53.244C53.7328 43.8787 54.1313 44.0406 54.4551 44.3628L54.4552 44.3629C54.7789 44.6846 54.9412 45.0804 54.9412 45.5658V52.7658C54.9412 53.2516 54.7795 53.6475 54.4575 53.9692L54.4574 53.9693C54.1358 54.2909 53.7399 54.4524 53.254 54.4524H46.054C45.569 54.4524 45.1735 54.2901 44.8517 53.9664L44.8516 53.9663C44.5294 53.6425 44.3675 53.244 44.3675 52.7552V47.1326C44.3675 46.7883 44.2307 46.4581 43.9872 46.2146L33.4187 35.646C32.9117 35.139 32.0896 35.139 31.5826 35.646L21.0141 46.2146C20.7706 46.4581 20.6338 46.7883 20.6338 47.1326V52.7552C20.6338 53.244 20.4719 53.6425 20.1497 53.9663L20.1496 53.9664C19.8279 54.2901 19.4321 54.4524 18.9467 54.4524H11.7467C11.2609 54.4524 10.865 54.2907 10.5433 53.9687Z" fill="url(#paint0_linear_1_7937)" stroke="url(#paint1_linear_1_7937)" stroke-width="0.452303"/>
            </g>
            <defs>
            <linearGradient id="paint0_linear_1_7937" x1="32.5007" y1="22.9092" x2="32.5007" y2="80.0272" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D9F4F3"/>
            <stop offset="1" stop-color="#D9F4F3" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1_7937" x1="32.5007" y1="22.9092" x2="32.5007" y2="76.3424" gradientUnits="userSpaceOnUse">
            <stop stop-color="#40B5AF"/>
            <stop offset="1" stop-color="#40B5AF" stop-opacity="0"/>
            </linearGradient>
            </defs>
        </svg>
    );
};
    
export default MobilityEdge;