import { jsx as r } from "react/jsx-runtime";
import { Box as n, Typography as c } from "@mui/material";
const s = ({
  selectedTime: t,
  onTimeChange: i,
  timeSlots: p
}) => /* @__PURE__ */ r(n, { sx: { display: "flex", flexDirection: "column", flex: 1, maxHeight: "184px", overflowY: "auto" }, children: p.map((o, x) => {
  const e = o === t;
  return /* @__PURE__ */ r(
    n,
    {
      sx: {
        padding: "8px 33px",
        textAlign: "center",
        backgroundColor: e ? "#F7F7F7" : "transparent",
        margin: "0px 10px",
        cursor: "pointer",
        ":hover": {
          backgroundColor: "#F7F7F7"
        }
      },
      onClick: () => i(o),
      children: /* @__PURE__ */ r(c, { sx: { fontSize: "12px", fontWeight: "500" }, children: o })
    },
    x
  );
}) });
export {
  s as TimePickerComponent
};
