import o from "moment-timezone";
function l(a, e, t) {
  const n = o(), r = e ?? n.unix(), s = a.match(/(\d+)([a-zA-Z]+)/);
  if (!s)
    throw new Error(`Invalid interval: ${a}`);
  const m = parseInt(s[1]), i = s[2].toLowerCase(), c = n.subtract(m, i).unix();
  if (t) {
    const u = Math.floor(c / t) * t, d = Math.ceil(r / t) * t;
    return { startTime: u, endTime: d };
  }
  return { startTime: c, endTime: r };
}
const f = (a) => {
  const e = o().unix(), t = a.match(/^(\d+)([a-zA-Z]+)$/);
  if (!t)
    throw new Error("Invalid presetValue format");
  const n = parseInt(t[1], 10), r = t[2];
  return { startDate: o.unix(e).subtract(n, r).unix(), endDate: e };
}, p = (a) => {
  const e = [], t = o().startOf("day"), n = o().endOf("day");
  for (; t <= n; )
    e.push(t.format("h:mm A")), t.add(a, "minutes");
  return e;
}, w = (a) => {
  const e = a.match(/^(\d+)([a-zA-Z]+)$/);
  if (!e)
    throw new Error("Invalid presetValue format");
  const t = e[1], n = e[2], r = n.charAt(0).toUpperCase() + n.slice(1).toLowerCase();
  return `${t} ${r}`;
};
export {
  f as calculateDateRange,
  w as formatPresetLabel,
  p as generateTimeSlots,
  l as getIntervalTime
};
