/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

type initState = {
    activeFlowStep: number | null;
    redirectToFlow: string | null;
    redirectHistory: string[];
    isConnectToEdge: boolean;
    showAllFields: boolean;
}

const initialState: initState = {
    activeFlowStep: null,
    redirectToFlow: null,
    redirectHistory: [],
    isConnectToEdge: false,
    showAllFields: false
}

export const onboardingSlice = createSlice({
    name: "onboarding",
    initialState,
    reducers: {
        setActiveFlowStep: (state, action: PayloadAction<number | null>) => {
            state.activeFlowStep = action.payload;
        },
        setRedirectToFlow: (state, action: PayloadAction<string | null>) => {
            if (action.payload === null) {
                state.redirectToFlow = null;
                state.redirectHistory = [];
            } else {
                if (state.redirectToFlow !== null) {
                    state.redirectHistory.push(state.redirectToFlow);
                }

                state.redirectToFlow = action.payload;
            }
        },
        removeLastRedirect: (state) => {
            if (state.redirectHistory.length > 0) {
                state.redirectHistory.pop();
            } else {
                state.redirectToFlow = null;
            }
        },
        setIsConnectToEdge: (state, action: PayloadAction<boolean>) => {
            state.isConnectToEdge = action.payload;
        },
        setRedirectHistory: (state, action: PayloadAction<string[]>) => {
            state.redirectHistory = action.payload;
        },
        setShowAllFields: (state, action: PayloadAction<boolean>) => {
            state.showAllFields = action.payload;
        }
    },
});

export const onboardingActions = onboardingSlice.actions;

export default onboardingSlice.reducer;

export const selectActiveFlowStep = (state: RootState) => state.onboarding.activeFlowStep;
export const selectRedirectToFlow = (state: RootState) => state.onboarding.redirectToFlow;
export const selectIsConnectedToEdge = (state: RootState) => state.onboarding.isConnectToEdge;
export const selectShowAllFields = (state: RootState) => state.onboarding.showAllFields;