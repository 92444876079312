/**
    Copyright Highway9 Networks Inc.
 */

type Props = {
    size?: number;
    height?: number;
    width?: number;
};

const NetworkArrowRight = ({
    height = 16,
    width = 44
}: Props) => {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.7071 8.7071C44.0976 8.31658 44.0976 7.68341 43.7071 7.29289L37.3431 0.928929C36.9526 0.538405 36.3195 0.538405 35.9289 0.928929C35.5384 1.31945 35.5384 1.95262 35.9289 2.34314L41.5858 8L35.9289 13.6569C35.5384 14.0474 35.5384 14.6805 35.9289 15.0711C36.3195 15.4616 36.9526 15.4616 37.3431 15.0711L43.7071 8.7071ZM8.74228e-08 9L43 9L43 7L-8.74228e-08 7L8.74228e-08 9Z" fill="url(#paint0_linear_1_7948)"/>
            <defs>
            <linearGradient id="paint0_linear_1_7948" x1="47.6909" y1="-6.49986" x2="-28.7027" y2="15.9776" gradientUnits="userSpaceOnUse">
            <stop stop-color="#40B5AF"/>
            <stop offset="1" stop-color="#40B5AF" stop-opacity="0"/>
            </linearGradient>
            </defs>
        </svg>
    );
};
    
export default NetworkArrowRight;