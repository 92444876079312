import { a as $ } from "./_commonjsHelpers-c5d32002.js";
import { c as w } from "./createSvgIcon-78894459.js";
import { e as N, C as T, b as _ } from "./styled-996d2964.js";
import { c as S, d as C, o as V, u as R } from "./useEventCallback-a69e3560.js";
import * as o from "react";
import { T as F, o as D, s as O, u as j, a as k } from "./useTimeout-890b47bd.js";
import { u as M } from "./unsupportedProp-3dbf01f6.js";
function P(e, t) {
  return process.env.NODE_ENV === "production" ? () => null : (n, r, u, s, i) => {
    const l = u || "<<anonymous>>", c = i || r;
    return typeof n[r] < "u" ? new Error(`The ${s} \`${c}\` of \`${l}\` is deprecated. ${t}`) : null;
  };
}
function K(e, t) {
  var n, r;
  return /* @__PURE__ */ o.isValidElement(e) && t.indexOf(
    // For server components `muiName` is avaialble in element.type._payload.value.muiName
    // relevant info - https://github.com/facebook/react/blob/2807d781a08db8e9873687fccc25c0f12b4fb3d4/packages/react/src/ReactLazy.js#L45
    // eslint-disable-next-line no-underscore-dangle
    (n = e.type.muiName) != null ? n : (r = e.type) == null || (r = r._payload) == null || (r = r.value) == null ? void 0 : r.muiName
  ) !== -1;
}
function U(e, t) {
  if (process.env.NODE_ENV === "production")
    return () => null;
  const n = t ? N({}, t.propTypes) : null;
  return (u) => (s, i, l, c, a, ...I) => {
    const h = a || i, b = n == null ? void 0 : n[h];
    if (b) {
      const y = b(s, i, l, c, a, ...I);
      if (y)
        return y;
    }
    return typeof s[i] < "u" && !s[u] ? new Error(`The prop \`${h}\` of \`${e}\` can only be used together with the \`${u}\` prop.`) : null;
  };
}
let g = 0;
function q(e) {
  const [t, n] = o.useState(e), r = e || t;
  return o.useEffect(() => {
    t == null && (g += 1, n(`mui-${g}`));
  }, [t]), r;
}
const v = o["useId".toString()];
function A(e) {
  if (v !== void 0) {
    const t = v();
    return e ?? t;
  }
  return q(e);
}
function G({
  controlled: e,
  default: t,
  name: n,
  state: r = "value"
}) {
  const {
    current: u
  } = o.useRef(e !== void 0), [s, i] = o.useState(t), l = u ? e : s;
  if (process.env.NODE_ENV !== "production") {
    o.useEffect(() => {
      u !== (e !== void 0) && console.error([`MUI: A component is changing the ${u ? "" : "un"}controlled ${r} state of ${n} to be ${u ? "un" : ""}controlled.`, "Elements should not switch from uncontrolled to controlled (or vice versa).", `Decide between using a controlled or uncontrolled ${n} element for the lifetime of the component.`, "The nature of the state is determined during the first render. It's considered controlled if the value is not `undefined`.", "More info: https://fb.me/react-controlled-components"].join(`
`));
    }, [r, n, e]);
    const {
      current: a
    } = o.useRef(t);
    o.useEffect(() => {
      !u && !Object.is(a, t) && console.error([`MUI: A component is changing the default ${r} state of an uncontrolled ${n} after being initialized. To suppress this warning opt to use a controlled ${n}.`].join(`
`));
    }, [JSON.stringify(t)]);
  }
  const c = o.useCallback((a) => {
    u || i(a);
  }, []);
  return [l, c];
}
let f = !0, p = !1;
const L = new F(), x = {
  text: !0,
  search: !0,
  url: !0,
  tel: !0,
  email: !0,
  password: !0,
  number: !0,
  date: !0,
  month: !0,
  week: !0,
  time: !0,
  datetime: !0,
  "datetime-local": !0
};
function z(e) {
  const {
    type: t,
    tagName: n
  } = e;
  return !!(n === "INPUT" && x[t] && !e.readOnly || n === "TEXTAREA" && !e.readOnly || e.isContentEditable);
}
function B(e) {
  e.metaKey || e.altKey || e.ctrlKey || (f = !0);
}
function d() {
  f = !1;
}
function W() {
  this.visibilityState === "hidden" && p && (f = !0);
}
function J(e) {
  e.addEventListener("keydown", B, !0), e.addEventListener("mousedown", d, !0), e.addEventListener("pointerdown", d, !0), e.addEventListener("touchstart", d, !0), e.addEventListener("visibilitychange", W, !0);
}
function X(e) {
  const {
    target: t
  } = e;
  try {
    return t.matches(":focus-visible");
  } catch {
  }
  return f || z(t);
}
function Y() {
  const e = o.useCallback((u) => {
    u != null && J(u.ownerDocument);
  }, []), t = o.useRef(!1);
  function n() {
    return t.current ? (p = !0, L.start(100, () => {
      p = !1;
    }), t.current = !1, !0) : !1;
  }
  function r(u) {
    return X(u) ? (t.current = !0, !0) : !1;
  }
  return {
    isFocusVisibleRef: t,
    onFocus: r,
    onBlur: n,
    ref: e
  };
}
const H = {
  configure: (e) => {
    process.env.NODE_ENV !== "production" && console.warn(["MUI: `ClassNameGenerator` import from `@mui/material/utils` is outdated and might cause unexpected issues.", "", "You should use `import { unstable_ClassNameGenerator } from '@mui/material/className'` instead", "", "The detail of the issue: https://github.com/mui/material-ui/issues/30011#issuecomment-1024993401", "", "The updated documentation: https://mui.com/guides/classname-generator/"].join(`
`)), T.configure(e);
  }
}, Q = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  capitalize: _,
  createChainedFunction: S,
  createSvgIcon: w,
  debounce: C,
  deprecatedPropType: P,
  isMuiElement: K,
  ownerDocument: D,
  ownerWindow: V,
  requirePropFactory: U,
  setRef: O,
  unstable_ClassNameGenerator: H,
  unstable_useEnhancedEffect: j,
  unstable_useId: A,
  unsupportedProp: M,
  useControlled: G,
  useEventCallback: R,
  useForkRef: k,
  useIsFocusVisible: Y
}, Symbol.toStringTag, { value: "Module" }));
var m = {};
const Z = /* @__PURE__ */ $(Q);
var E;
function se() {
  return E || (E = 1, function(e) {
    Object.defineProperty(e, "__esModule", {
      value: !0
    }), Object.defineProperty(e, "default", {
      enumerable: !0,
      get: function() {
        return t.createSvgIcon;
      }
    });
    var t = Z;
  }(m)), m;
}
export {
  se as r,
  A as u
};
