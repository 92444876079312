import { CACertificate } from "~/types/CACertificate";
import APIService from "./APIServices";

const CACertAPI = new APIService<CACertificate>("certificates");

export const cacertConfigService = {
  getCACertConfigData : CACertAPI.getAll,
  createCACertConfig : CACertAPI.create,
  deleteCACertConfig : CACertAPI.delete
};
