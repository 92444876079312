import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { authenticationService, supportChannelServices, systemService } from "../../services";
import { SupportChannel } from "../../types/SupportChannel";
import { System } from "../../types/systemSetting";

type SettingsState = {
  system: System[];
  support: SupportChannel;
  loading: boolean;
  error: string | null;
};

const initialState: SettingsState = {
  system: [],
  support: {
    enabled: false,
    id: "",
    state: "INACTIVE",
  },
  loading: true,
  error: null,
};

export const fetchSystemSettings = createAsyncThunk("settings/fetchSystemSettings", async () => {
  const response = await systemService.getSystemSettingsData();
  return response;
});

export const fetchSupportChannel = createAsyncThunk("settings/fetchSupportChannel", async () => {
  const result = await supportChannelServices.getSupportChannel();
  return result;
});

const SettingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSystemSettings(state, action: PayloadAction<System[]>) {
      state.system = action.payload;
    },
    setSupportSettings(state, action: PayloadAction<SupportChannel>) {
      state.support = action.payload;
    },
    setSupportSettingsEnabled(state, action: PayloadAction<boolean>) {
      state.support.enabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystemSettings.fulfilled, (state, action) => {
        state.system = action.payload;
        state.loading = false;
      })
      .addCase(fetchSystemSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSystemSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Something went wrong";
      })

      // Support Channel Fetch
      .addCase(fetchSupportChannel.fulfilled, (state, action) => {
        state.support = action.payload;
        state.loading = false;
      })
      .addCase(fetchSupportChannel.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSupportChannel.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Something went wrong";
      });
  },
});

export const settingsActions = SettingsSlice.actions;
export default SettingsSlice.reducer;

export const settingsSystem = (state: RootState) => state.settings.system;
export const settingsSupport = (state: RootState) => state.settings.support;
export const settingsSupportEnabled = (state: RootState) => state.settings.support.enabled;
export const settingsSystemDashboardConsoleEnabled = (state: RootState) => state.settings.system.some((item) => item.name === "dashboard.console.enabled" && item.value);
export const settingsSystemOnboardingEnabled = (state: RootState) => state.settings.system.some((item) => item.name === "dashboard.getting.started.enabled"
 && item.value);
export const settingsAssistantEnabled = (state: RootState) => authenticationService.isOperator() || state.settings.system.some((item) => item.name === "system.chatbot.enabled" && item.value)
export const settingsDashboardInsightsEnabled = (state: RootState) => state.settings.system.some((item) => item.name === "dashboard.insights.enabled" && item.value)
export const settingsLoading = (state: RootState) => state.settings.loading;
